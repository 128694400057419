import { createSlice } from "@reduxjs/toolkit";
const  CategorycityReducer=createSlice({
  name:'Categorycity',
  initialState:{
    CityCategory:[],
    loading:false,
    error:false
  },
  reducers:{
      setCategorycity(state,action){
        state.CityCategory=action.payload;
      },
      setLoading(state,action){
        state.loading=action.payload;
      },
      setError(state,action){
        state.error=action.payload;
      }
  }

})

 export const{setCategorycity,setLoading,setError}=CategorycityReducer.actions;
 export default CategorycityReducer.reducer;