import React, {useState} from 'react';
import { CiLocationOn } from "react-icons/ci";
import Hand from '../../../assests/Images/homepics/callvet-hero 1.svg';
import Selectoption from '../../pages/home/selectoption';

const Banner = () => {
  const [popupshow,setPopupshow]=useState(false);
const handlepopup=(index)=>{
  setPopupshow(index)
}
const handleclose=()=>{
  setPopupshow(null)
}
  return (
    <div className='w-full h-[500px]  sm:h-auto   '>
      <div className='w-11/12 flex flex-col mx-auto pt-1 xl:pt-10 2xl:pt-32  pb-24 '>
        <div className='flex flex-row w-full 0 mt-8   '>
          <div className='w-full sm:w-2/4 py-3 xl:py-0'>
            <h1 className='text-2xl sm:text-[26px] md:text-4xl lg:text-[40px] xl:text-[50px] text-primary font-semibold leading-8 sm:leading-9 md:leading-[50px] xl:leading-[75px] pt-5 lg:pt-20 xl:pt-16 '>
              Find your desired Vet Doctor Right Now!
            </h1>
          </div>
          <div className='w-0 sm:w-2/4 flex justify-end relative  '>
            <div className='absolute right-0 sm:bottom-[-58px] md:bottom-[-46px] lg:bottom-[-25px] xl:bottom-0'>
              <img src={Hand} alt='img' className='sm:w-[300px] md:w-[350px] lg:w-[424px] xl:w-[524px] h-[271px]  hidden sm:block' />
            </div>
          </div>
        </div>
        <form>
          <div className='flex flex-col sm:flex-row w-full bg-white h-auto xl:h-14 rounded items-center '>
            {/* City Input */}
            <div className='w-full sm:w-[35%] h-full border-b-[1px] sm:border-b-0 sm:border-r-2 relative py-3 sm:py-0 sm:rounded-tl sm:rounded-bl '>
              <CiLocationOn className='absolute text-xl top-3 sm:top-[2px] xl:top-4 left-2 text-primary'/>
              <input
                type='text'
                placeholder='City, state, or Zip code'
                className='w-full h-full px-8 outline-none text-[15px]'
                onClick={()=>handlepopup(1)}
                // value={intialcity}

              />
        
            </div>
            <div className='w-full sm:w-[50%] h-full py-3 xl:py-0 relative'>
              <input
                type='text'
                placeholder='Search for doctors,services'
                className='w-full h-full px-2 sm:px-8 outline-none text-[15px]'
                onClick={()=>handlepopup(2)}
              />
        
            </div>
            {/* Search Button */}
            <div className='w-full sm:w-[15%]'>
              <button
                className='w-full bg-[#4C63BD] text-white flex justify-center items-center sm:h-14 py-2 sm:py-0 rounded-bl rounded-br sm:rounded-bl-none sm:rounded-tr sm:rounded-br text-lg'
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
        {/* Render Popup based on selection */}
{popupshow===1 ? <Selectoption handleclose={handleclose}/>:''}
{popupshow===2 ? <Selectoption handleclose={handleclose}/>:''}
    </div>
  
  );
};

export default Banner;
