import React, { useEffect } from 'react'
import Sidebar from './sidebar'
import Navbardeatil from './navbardeatil'
import Headingdoctor from './left/headingdoc'
import Profiledoc from './left/profiledoc'
import Servicesdoc from './left/servicesdoc'
import Eduction from './left/eduction'
import Otherinfo from './left/otherinfo'
import Aboutreviws from './left/aboutreviws'
import Bookingconfirm from './left/bookingconfirm'
import { useParams } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux'
import  {fetchDoctorDetails} from '../../../redux/actions/viewprofileaction'
import LoadingImage from '../../../assests/Images/globalpics/loadingimg.svg'
const Main = () => {
  const {id}=useParams();
  const dispatch=useDispatch();
  const  {ViewPro,loading,error}=useSelector((state)=>state.view);
  useEffect(()=>{
   dispatch(fetchDoctorDetails(id))
   window.scrollTo(0, 0);
  },[dispatch,id])
  if(loading){
    return(
      <div className='w-full h-screen flex justify-center items-center bg-[#F8F9FA]'>
      <img src={LoadingImage} alt='Loading' className='w-16 h-16' />
  </div>
    );
  }
  if (error) {
    return <div>Error: {error}</div>;
}
if (!ViewPro) {
    return <div>No doctor details found.</div>;
}
  return (
    <div className='w-full'>
      <Navbardeatil/>
      <div className='w-11/12  mx-auto py-7'>
         <div className='flex flex-row space-x-6'>
             <div className='w-full lg:w-3/5 xl:w-[70%] flex flex-col '>
             <Headingdoctor  doctorcity={ViewPro.hospitals} doctorName={ViewPro} doctorcategorey={ViewPro.categories}/>
             <div className='section' id='Feedback'>
             <Profiledoc   doctorDetails={ViewPro}  />
             </div>
             <div className='block lg:hidden'>
             <Bookingconfirm doctorhospital={ViewPro.hospitals}   doctorsid={ViewPro}/>
             </div>
             <div className='section' id='ClinicDetails'>
             <div className='h-2'></div>
             </div>
             <div className='section' id='Services' >
               <Servicesdoc  doctorservices={ViewPro.services} />
             </div>
             <div className='section' id='Education'>
             <Eduction    edu={ViewPro.education}/>
             </div>
             <div className='section' id='OtherInfo' >
             <Otherinfo  doctorExperince={ViewPro.experience} doctordegree={ViewPro.education} doctorclincinformation={ViewPro.hospitals}  doctornamedeatil={ViewPro}   doctorcategorey={ViewPro.categories} doctorservices={ViewPro.services} />
             </div>
             <div className='section' id='Reviews'>
             <Aboutreviws doctornamerated={ViewPro}/>
             </div> 
             </div>
             <div className='lg:w-2/5 xl:w-[30%] sticky top-0 z-40 hidden lg:block'  style={{height:'h-screen'}}>
             <Sidebar doctorhospital={ViewPro.hospitals}   doctorsid={ViewPro}  />
             </div>
         </div>
      </div>
    </div>
  )
}

export default Main
