import React, { useState,useEffect } from 'react'
import { FaCheck } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { LuUser2 } from "react-icons/lu";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdOutlinePayment } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { IoLocation } from "react-icons/io5";
import './appoinetmentfor.css';
/// set a pop up
// onClick={handleclicksomeone}
// ,setShowpopup
// const handleclicksomeone=()=>{
//   setShowpopup(true);
// }
// const [appoinetmentactive,setAppoinetmentactive]=useState(null);

const   AppoinetmentFor= ({phonenumber,pateinname,setPateintName,setPayment,addpateintname,pateinterror,paymenterror,petname,setPetname,selectedPet,setSelectedPet,patnameerror,selectedPeterror,doctorfee,homecheckup,payment}) => {
const [showactive,setShowactive]=useState(null);
const [showpets,setShowpets]=useState(false);
const [petnumber,setPetNumber]=useState(phonenumber);
const pets = ["Cat","Dog","Horse","Birds","Goat","Cow","Rabbit","Sheep","Fish","Pets"];
const handleclik = (index) => {
  setShowactive(index);
  setPayment(index);
  console.log(showactive);
 
};
const handletoshowpet=()=>{
  setShowpets(!showpets)
}

const handleSelectPet = (pet) => {
  setSelectedPet(pet);
  setShowpets(false); 
}
useEffect(() => {
  const storedAuthData = localStorage.getItem('authData');
  const authData = storedAuthData ? JSON.parse(storedAuthData) : null;
  if (authData && authData.user && authData.user.phone) {
    setPetNumber(authData.user.phone);
    console.log('show data', authData.user.phone);
  }
}, []); 

  const handlepateintName = (e) => {
    setPateintName(e.target.value);
  }
const handlepetname=(e)=>{
  setPetname(e.target.value)

}
// const handleclikappont=(index)=>{
//     setAppoinetmentactive(index)
//     setAppoinetmentselection(index)
// } 


  return (
       <>
       <div className='w-full box  rounded py-10 bg-white '>
       <div className='flex flex-col space-y-5 '>
          <div className='flex flex-col space-y-4 px-10 '>
              <div className='text-lg text-[#232426] font-medium'>
              Appointment For
              </div>
              <div className='flex flex-row space-x-5 cursor-pointer'>
              <div className='flex text-[15px] items-center space-x-1 text-[#46484b] '>
              {addpateintname}
              </div>
              </div>
          </div>
          <div className='flex flex-col space-y-4 w-full  '>
          <div className='flex flex-col space-y-3'>
          <div className='flex flex-row px-2  items-center space-x-3'>
             <div className='text-2xl text-[#46484b] md:text-opacity-50'>
                 <LuUser2/>
             </div>
             <div className='text-lg text-[#232426] font-medium'>
             Pet Owner
             </div>
           </div>
           <div className='mx-10'>
              <input type='text' placeholder='Enter Pet Owner Name' value={pateinname}   onChange={handlepateintName}    className='w-11/12 border-b-[1px] outline-none py-1 text-sm  font-light px-1'  />
              {pateinterror && <p className='text-xs text-[#E6220C]'>{pateinterror}</p>}
           </div>
          </div>



          
          </div>
      <div className=' flex flex-col space-y-2 sm:flex-row sm:space-x-4  w-11/12 mx-10 '>
      <div className='flex flex-col space-y-2 sm:w-1/2   '>
               <label className='text-[#46484b]'>Phone Number</label>
              <input type='text'   value={petnumber}  className='w-full border outline-none py-3 px-3 rounded-md bg-[#F8F9FA]' readOnly required/>
              <p className='flex items-center text-xs space-x-1 text-[#46484b] text-opacity-70'><span><IoIosInformationCircleOutline/></span><span>You will be contacted through this number</span></p>
           </div>
           <div className='flex flex-col space-y-3 sm:w-1/2 relative '>
          <label  className='text-lg text-[#232426] font-medium '>Pet Type</label>
          <div className='relavtive w-full cursor-pointer ' onClick={handletoshowpet}>
          <input type='text' placeholder='Select Pet' className='w-full border-b-[1px] outline-none font-light  py-2  cursor-pointer text-sm px-2  ' value={selectedPet} readOnly />
          <IoIosArrowDown className='absolute top-12  right-4  cursor-pointer '/>
          </div>
          {selectedPeterror && <p className='text-xs text-[#E6220C]'>{selectedPeterror}</p>}
          {
            showpets &&(
              <div className='absolute border rounded bg-white h-72 top-24   z-10 w-full overflow-x-hidden'>
              <ul className={`w-full flex flex-col cursor-pointer `}  >
          {pets.map((selection)=>(
            <li key={selection} className='border-b-[1px] py-3 px-3  hover:bg-[#F8F9FA]' onClick={() => handleSelectPet(selection)}  >{selection}</li>
          ))}
          </ul>
              </div>
             
            )
          }
          </div>
      </div>

         
        
        
          <div className='flex flex-col px-10  w-11/12'>
          <label className='text-lg text-[#232426] font-medium'>Pet Name</label>
  <input type='text' value={petname} placeholder='Enter pet Name'  className=' border-b-[1px] outline-none text-sm   py-2 font-light  px-1' onChange={handlepetname}/>
  {patnameerror && <p className='text-xs text-[#E6220C]'>{patnameerror}</p>}
          </div>

  {homecheckup  ?   (
    <>
  <div className='flex flex-col space-y-2 w-full  '>

<div className='flex flex-row px-2 items-center space-x-3'>
             <div className='text-xl text-[#46484b] text-opacity-70'>
                 <IoLocation/>
             </div>
             <div className='text-lg text-[#232426] font-medium'>
             Address for Home Checkup
             </div>
           </div>
           <div className='flex flex-row  px-10'>
      <input type="text"  className='w-11/12  border-b-[1px]  outline-none text-sm   py-2 font-light ' placeholder='Enter your Address'/>

</div>
</div>

</>
  ): ''  }
 



<div className='flex flex-col space-y-4 w-full '>
<div className='flex flex-row px-2 items-center space-x-3'>
             <div className='text-xl text-[#46484b] text-opacity-70'>
                 <MdOutlinePayment/>
             </div>
             <div className='text-lg text-[#232426] font-medium'>
             Select Payment Method
             </div>
           </div>

        <div className='flex flex-col mx-10   space-y-2'>
        <div className={` ${showactive===0 ? 'border border-[#98abf7] text-[#46484b] text-opacity-100 bg-[#4C63BD4D]':'border  text-[#46484b] text-opacity-60 bg-white '} flex items-center justify-between rounded-md px-3 w-11/12  cursor-pointer py-2`} onClick={()=>handleclik(0)}>
        <div className='flex items-center space-x-2 py-2 '>
        <input  type="checkbox" class="rounded-checkbox"   
 id="online-payment"       checked={showactive === 0}

 onChange={()=>{}}/>
        <label for="online-payment " style={{fontSize:'14px'}}>Online payment</label>
        </div>
        <div className='text-sm'>
        {doctorfee}
        </div>
        </div>
        <div className={` ${showactive===1 ? 'border border-[#4C63BD] text-[#46484b] text-opacity-100 bg-[#4C63BD4D]':'border text-[#46484b] text-opacity-60  bg-white'} flex items-center justify-between rounded-md px-3 w-11/12  cursor-pointer py-2`} onClick={()=>handleclik(1)}>
        <div className='flex items-center space-x-2 py-2 '>
        <input  type="checkbox" class="rounded-checkbox"   
 id="online-payment"       checked={showactive === 1}

 onChange={()=>{}} />
        <label for="online-payment " style={{fontSize:'14px'}}>Pay Cash at clinic</label>
        </div>
        <div className='text-sm'>
        {doctorfee}
        </div>
        </div>
        {paymenterror && <p className='text-xs text-[#E6220C]'>{paymenterror}</p>}

     
            
        </div>
           

</div>










         

       </div>
       </div>

       </>
  )
}

export default   AppoinetmentFor
