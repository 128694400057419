import React, { useState } from 'react'
import { CiClock2 } from "react-icons/ci";
import LoadingImage from '../../../assests/Images/globalpics/loadingimg.svg'

const Stickyconfirm = ({selectday, selectmonth,selecttime, handleConfirmClick,image,username,mainloading}) => {
  return (
     <>
    
        <div className='w-full sticky bottom-0 z-30 py-2 px-1  bg-[#F8F9FA] block md:hidden'>


        {
      mainloading ?
      (
        <div className='w-full  h-14 flex justify-center items-center '>
            <img src={LoadingImage} alt='Loading...' className='w-10 h-10' />
          </div>
      ):(
           <>
        <div className='flex justify-between items-center   px-5 py-3'>
        <div className='flex items-center space-x-2 bg-[#F4F7FF] px-3 rounded-md py-1'>
        <div> <CiClock2/></div>
        <div className='text-sm flex space-x-1 '>
        <span>{selectday}</span>
         <span>{selectmonth}</span>
         <span>{selecttime}</span>
        </div>
        </div>
        <div className='flex items-center space-x-1   '>
        <img src={`https://callvet.raabtastore.com/${image}`} alt='img' className='h-11 w-11 rounded-full'/>
         <p className='text-sm'>{username}</p>
        </div>
        </div>
          <button className='bg-[--primary] text-white w-full py-2 rounded-md  ' onClick={handleConfirmClick}>Confrim booking</button>
          </>
      )
     }
        
        </div>
     </>
  )
}

export default Stickyconfirm
