import { createSlice } from "@reduxjs/toolkit";
const CityInfoReducer=createSlice({
  name:'CityName',
  initialState:{
     Cityinfo:[],
     loading:false,
     error:false
  },
  reducers:{
    setCityName(state,action){
        state.Cityinfo=action.payload;
    },
    setLoading(state,action){
        state.loading=action.payload;
    },
    setError(state,action){
        state.error=action.payload;
      }
  }
})
export const{setCityName,setLoading,setError}=CityInfoReducer.actions;
export default CityInfoReducer.reducer;