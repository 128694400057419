import React from 'react';
import Images1 from '../../../assests/Images/homepics/pana.svg';
import Images2 from '../../../assests/Images/homepics/Group 1261152961.svg';
import Images3 from '../../../assests/Images/homepics/Group 1261152960.svg';
import { FaLongArrowAltRight } from "react-icons/fa";
import './chome.css';

const Letgetsyou = () => {
  return (
    <div className='w-full'>
      <div className='w-11/12 mx-auto py-5'>
        <div className='text-center my-7 w-full pt-4 mx-auto text-2xl sm:text-3xl xl:text-4xl text-black font-bold'>
          How does it Work
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
          {/* Card 1 */}
          <div className='bg-white relative h-auto shape sm:mx-auto hover:shadow-xl mt-4'>
            <div className='w-[140px] h-[140px] xl:w-[180px] xl:h-[180px] mt-[-20px] shadow-xl rounded-full flex items-center justify-center mx-auto bg-white'>
              <img src={Images1} alt='img' className='w-24 h-24 xl:w-[120px] xl:h-[120px]' />
            </div>
            <div className='flex flex-col space-y-5 py-10'>
              <h2 className='text-center text-xl md:text-2xl font-semibold text-[--primary]'>Track Your Order</h2>
              <p className='text-sm text-[#686868] w-4/5 mx-auto text-center'>We will deliver your food within 30 minutes in your town, If we would.</p>
              <button className='flex items-center mx-auto space-x-2 text-base'><span>Read More</span><span><FaLongArrowAltRight /></span></button>
            </div>
          </div>

          {/* Card 2 */}
          <div className='bg-white relative h-auto shape xl:shadow-xl sm:mx-auto hover:shadow-xl mt-4'>
            <div className='w-[140px] h-[140px] xl:w-[180px] xl:h-[180px] mt-[-20px] shadow-xl rounded-full flex items-center justify-center mx-auto bg-white'>
              <img src={Images2} alt='img' className='w-24 h-24 xl:w-[120px] xl:h-[120px]' />
            </div>
            <div className='flex flex-col space-y-5 py-10'>
              <h2 className='text-center text-xl md:text-2xl font-semibold text-[--primary]'>Track Your Order</h2>
              <p className='text-sm text-[#686868] w-4/5 mx-auto text-center'>We will deliver your food within 30 minutes in your town, If we would.</p>
              <button className='flex items-center mx-auto space-x-2 text-base'><span>Read More</span><span><FaLongArrowAltRight /></span></button>
            </div>
          </div>

          {/* Card 3 */}
          <div className='bg-white relative h-auto shape sm:mx-auto hover:shadow-xl mt-4'>
            <div className='w-[140px] h-[140px] xl:w-[180px] xl:h-[180px] mt-[-20px] shadow-xl rounded-full flex items-center justify-center mx-auto bg-white'>
              <img src={Images3} alt='img' className='w-24 h-24 xl:w-[120px] xl:h-[120px]' />
            </div>
            <div className='flex flex-col space-y-5 py-10'>
              <h2 className='text-center text-xl md:text-2xl font-semibold text-[--primary]'>Track Your Order</h2>
              <p className='text-sm text-[#686868] w-4/5 mx-auto text-center'>We will deliver your food within 30 minutes in your town, If we would.</p>
              <button className='flex items-center mx-auto space-x-2 text-base'><span>Read More</span><span><FaLongArrowAltRight /></span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Letgetsyou;
