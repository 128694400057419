import React from 'react';
import { CiCircleCheck } from "react-icons/ci";
import google from '../../../assests/Images/png/android.png'
import Apple from  '../../../assests/Images/png/applestore.png'
import Callvetmobile from '../../../assests/Images/Svg/callvethomepage.svg'

const DownloadApp = () => {
  return (
    <div className="w-full bg-[#F8F9FA] my-4 rounded-md">
      <div className="flex flex-row py-5">
        <div className="w-3/5 px-7 flex flex-col justify-center">
          <h2 className="py-2 text-[26px]">Download the Callvet App</h2>
          <ul className="flex flex-col space-y-2">
            <li className="flex flex-row items-center space-x-3 text-[15px]">
              <span className="text-[--primary]"><CiCircleCheck/></span>
              <span>Instant Video Consultation</span>
            </li>
            <li className="flex flex-row items-center space-x-3 text-[15px]">
              <span className="text-[--primary]"><CiCircleCheck/></span>
              <span>Exclusive Discounts on Callvet App</span>
            </li>
          </ul>
          <div className="my-12 flex flex-col space-y-3">
            <p className="text-xs text-opacity-75">Get the Callvet App</p>
            <div className="flex flex-row w-3/5 space-x-4 cursor-pointer">
              <img src={google} alt="Google Play" className="w-2/4" />
              <img src={Apple} alt="Apple App Store" className="w-2/4" />
            </div>
          </div>
        </div>
        <div className="w-2/5">
          <div className="w-2/4">
            <img src={Callvetmobile} alt="Callvet Mobile" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
