import React, { useEffect } from 'react'
import {fetchapiservices} from '../../../redux/actions/servicesaction';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Bestspeclist = ({cityname}) => {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const {ServiesDvm}=useSelector((state)=>state.services);
    useEffect(()=>{
      dispatch(fetchapiservices());
    },[dispatch])
    const handledoctor=(city,category)=>{
        navigate(`/doctors/${city}/${category}`)
    }
  return (
    <div className='w-full bg-[#F8F9FA] my-4 py-3 rounded-md '>
      <h1 className='py-2 text-[26px] px-3'>Best Specialists in {cityname}</h1>
        {
            ServiesDvm.map((categories)=>(
                <p className='text-base px-4 py-2 cursor-pointer ' onClick={()=>handledoctor(cityname,categories._id)} key={categories._id}>Best {categories.name} in {cityname} </p>
            ))
        }
        
    </div>
  )
}

export default Bestspeclist
