// get Appionment
import { setPayCash,setLoading,setError } from "../reducers/paycashreducer";
import axios from "axios";
export const fetchPayCash=(appionmentId)=>async(dispatch)=>{
   try{
      dispatch(setLoading(true));
      const response =await axios.get(`https://callvet.raabtastore.com/api/appointment/${appionmentId}`)
        dispatch(setPayCash(response.data.data.doc));
    } 
    catch(error){
        console.log('Error are showing',error);
        dispatch(setError(true)); 
    }
    finally{
        dispatch(setLoading(false));

    }
}