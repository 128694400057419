import React,{useEffect} from 'react'
import { FaPhoneAlt } from "react-icons/fa";
import { fetchDoctorDetails } from '../../../redux/actions/viewprofileaction';
import { useParams,useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
const Stickybar = () => {
   const {id}=useParams();
   const dispatch=useDispatch();
   const navigate=useNavigate();
   const {ViewPro}=useSelector((state)=>state.view);
   useEffect(()=>{
   dispatch(fetchDoctorDetails(id))
   },[dispatch,id])
//   date
const currentDate=new Date();
const year=currentDate.getFullYear();
const month=String(currentDate.getMonth()+1).padStart(2,'0');
const day=String(currentDate.getDate()).padStart(2,'0');
const date= `${year}-${month}-${day}`;
//doctorid
const doctorid=ViewPro._id;
//hospital.id
const hospitalid = ViewPro?.hospitals?.[0]?._id;
const handleBookAppointment = (hospital) => { 
   navigate(`/appoinetment/${doctorid}/${hospital}`)
 };

  return (
     <>
        <div className='bg-[#F8F9FA] w-full sticky bottom-0 z-50 block md:hidden'>
        <div className='flex flex-row space-x-2 py-2 px-1'>
         <button className='w-1/2 bg-[#28B446] text-white py-2 rounded-md flex flex-row space-x-1 justify-center items-center'>
         <span><FaPhoneAlt/></span>   <span>Call Helpline</span>
         </button>
         <button className='w-1/2 bg-[--primary] text-white py-2 rounded-md' onClick={()=>handleBookAppointment(hospitalid)} >
          Book Appoinetment
         </button>
         
        </div>
        </div>
     </>
  )
}
export default Stickybar
