import React,{useState} from 'react'
import { FaHospitalAlt } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import { FaClock } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
const Bookingconfirm = ({doctorhospital,doctorsid}) => {
  console.log(doctorsid.bio);
  const navigate=useNavigate();
    const [expand,setExpand]=useState(false);
    const  handleExpand=(index)=>{
      setExpand(index)
    }
    const currentDate=new Date();
    const year=currentDate.getFullYear();
    const month=String(currentDate.getMonth()+1).padStart(2,'0');
    const day=String(currentDate.getDate()).padStart(2,'0');
    const date= `${year}-${month}-${day}`;
    const handleBookAppointment = (doctorhosp) => {
      navigate(`/appoinetment/${doctorsid._id}/${doctorhosp._id}`)
    };
  return (
      <>
<div className='flex flex-col  space-y-7 py-5  '>
        {/* box section */}
   {
    doctorhospital && doctorhospital.map((doctorhosp,index)=>(
      <div  className='flex flex-col space-y-5 px-2 py-4 box-shadow rounded-md cursor-pointer mt-6 bg-white ' key={doctorhosp._id}>
           <div className='flex flex-row  items-center space-x-2 px-1     '>
              <div className='text-[--primary]'>
              <FaHospitalAlt/>
              </div>
              <div className='text-[22px] font-medium leading-6'>
                    {doctorhosp.selectHospital.name}
              </div>
           </div>
           <div className=' flex flex-row justify-between px-2 py-1 border-b-[1px] text-sm '>
             <div className='text-black text-opacity-60'>Fee:</div>
             <div className=' text-black'>Rs. {doctorhosp.fee}</div>
           </div>
           <div className='flex flex-row justify-between px-2 py-1 border-b-[1px] text-sm '>
           <div className='text-black text-opacity-60'>Address:</div>
             <div className=' flex flex-row  font-medium items-center space-x-1 '>
             <span><IoLocation/></span> 
             <span className='underline'>{doctorhosp.selectHospital.address}</span>
             </div> 
           </div>
           <div className='flex flex-col  space-y-3' onClick={()=>handleExpand(index)}>
           <div className='flex flex-row justify-between px-2 py-1  text-sm'>
                <div className='flex flex-row items-center space-x-1 text-[#28B446]'>
                <span><FaClock/></span>
                <span> Available time  </span> 
                </div>
                <div className='flex flex-row items-center space-x-1 text-black' >
                   <span><IoIosArrowDown/></span>
                </div>
             </div>
              { 
            <div className='flex flex-col w-full space-y-2 transition-max-height duration-500  overflow-hidden px-2 text-black text-sm ' style={{ maxHeight:expand===index ?'1000px':'0'}}>
               {
    Object.keys(doctorhosp).filter(day => doctorhosp[day].status).map(day => (
      <div key={day} className='flex flex-row justify-between '>
        <div>{day.charAt(0).toUpperCase() + day.slice(1)}</div>
        <div>
          {doctorhosp[day].availableRange.map(range => (
            <span key={range}>{range[0]} - {range[1]}</span>
          ))}
        </div>
      </div>
    ))
  }
            </div>
           } 
           </div>
              <button className='w-11/12 mx-auto rounded py-2 text-center text-sm bg-[--primary] text-white' onClick={() => handleBookAppointment(doctorhosp)}>
                Book {doctorhosp.homeCheckUp ? 'Home Checkup':'Clinic Checkup'} Appointment
              </button>
        </div>
    ))

   }
        </div>
    
      </>
  )
}

export default Bookingconfirm
