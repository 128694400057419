// category api
import {setServicesList,setLoading} from '../reducers/servicesreducer';
import axios from 'axios';
export const fetchapiservices=()=>async(dispatch)=>{
    try{
        dispatch(setLoading(true));
        const response=await axios.get('https://callvet.raabtastore.com/api/category')
        dispatch(setServicesList(response.data.data.docs)); 
    }
    catch(error){
        console.log('Error are showing',error);
    }
    finally{
        dispatch(setLoading(false));
    }
}