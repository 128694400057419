import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Headroom from 'react-headroom';
// Import components
import Navbar from '../../components/global/navbar';
import Heading from '../../components/pages/Doctor/heading';
import Doctorlist from '../../components/pages/Doctor/doctorlist';
import Footer from '../../components/global/footer/index';
import Doctorslider from '../../components/pages/Doctor/doctorslider';
// Import assets
import LoadingImage from '../../assests/Images/globalpics/loadingimg.svg';
import notfound from '../../assests/Images/globalpics/not-found.png';
// Import actions
import { fetchapicatergorycity } from '../../redux/actions/categorycityaction';
const Doctor = () => {
  const dispatch = useDispatch();
  const { city, category } = useParams();
  const { CityCategory, loading } = useSelector((state) => state.citycategory);
  // Fetch doctors based on city and category
  useEffect(() => {
    if (city && category) {
      dispatch(fetchapicatergorycity(city, category));
      window.scrollTo(0, 0);
    }
  }, [dispatch, city, category]);
  return (
    <>
      {/* Navbar with Headroom for sticky effect */}
      <Headroom style={{ zIndex: '50' }} className="mb-2">
        <div className="navshadow bg-white">
          <Navbar />
        </div>
      </Headroom>
      {/* Heading section */}
      {CityCategory && CityCategory.length > 0 && (
        <div>
          <Heading 
            doctorctiy={city} 
            doctorcategories={CityCategory[0].categories.filter(cat => cat._id === category)} 
          />
        </div>
      )}
      {/* Doctorslider */}
      <div className="w-full h-auto sticky top-0 z-20 py-0 md:py-2 bg-white shadow-md ">
        <Doctorslider />
      </div>
      {/* Doctor list or loading/not-found states */}
      <div className="w-full z-10">
        {loading ? (
          <div className="w-full h-screen flex justify-center items-center bg-[#F8F9FA]">
            <img src={LoadingImage} alt="Loading" className="w-16 h-16" />
          </div>
        ) : CityCategory && CityCategory.length > 0 ? (
          CityCategory.map((doctor) => (
            <Doctorlist
              key={doctor._id}
              doctorId={doctor._id}
              doctordetails={doctor.bio}
              username={doctor.username}
              yearofexperince={doctor.yearsOfExperience}
              pictue={doctor.image}
              hospital={doctor.hospitals}
              
            />
          ))
        ) : (
          <div className="w-full h-screen flex flex-col space-y-6 items-center bg-[#F8F9FA]">
            <div className="w-3/5 mx-auto flex justify-center mt-40">
              <img src={notfound} alt="No Match found" />
            </div>
            <p className="text-[--primary] text-xl">No Match found</p>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Doctor;
