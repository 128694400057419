import React, { useEffect } from 'react'
import Navbar from '../../../global/navbar'
import Pateintdetail from './paycashcomp/pateintdetail'
import Footer from '../../../global/footer'
import Downloadapp from './paycashcomp/downloadapp'
import {useParams} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import Loadingimage from '../../../../assests/Images/globalpics/loadingimg.svg';
import { useDispatch, useSelector } from 'react-redux'
import { fetchPayCash } from '../../../../redux/actions/paycashaction'
const  Paycash = () => {
  const dispatch=useDispatch();
  const {appointmentId} = useParams();
  const { PaycashItems, loading } = useSelector((state) => state.paycash);
useEffect(()=>{
  if(appointmentId){
    dispatch(fetchPayCash(appointmentId));
  }
},[dispatch,appointmentId])



if (loading) {
  return <div className='w-full h-screen flex justify-center items-center bg-[#F8F9FA] '>
  <img src={Loadingimage} alt='Loading' className='w-16 h-16' />
</div> ;
}
const  Ownername= PaycashItems?.pet?.petOwnerName;
const  fee=PaycashItems?.hospitalAvailable?.fee;
const  hospitallocation=PaycashItems?.hospitalAvailable?.selectHospital?.address;
const   time=PaycashItems?.time;
const doctorname=PaycashItems?.doctorName;
  return (
    <>
    <div className='bg-[#F8F9FA]'>
    <div className='navshadow bg-white z-50'>
    <Navbar/>
    </div>
    <div className='w-full  '>
    <div className='w-11/12 mx-auto '>
     <h2 className='text-xl pt-6 font-medium'>Appointment Details</h2>
    <div className='w-full lg:w-4/5 xl:w-1/2 mx-auto my-3'>
       {/* <Cofirmsms/> */}
       <Pateintdetail  name={Ownername} fee={fee}   address={hospitallocation} time={time} doctor={doctorname} />
       {/* <Importantnote/> */}
       <Downloadapp/>
       {/* <Completeprofile/> */}
       </div>
    </div>
      

    </div>
      
    <Footer/>
    </div>
  
    </>
  )
}

export default  Paycash
