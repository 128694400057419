  import { createSlice } from "@reduxjs/toolkit";
  const initialState = {
    loading: false,
    appointment: '',
    error: null,
    message:''
  };
  const appointmentDetailReducer = createSlice({
    name: 'appointmentDetail',
    initialState,
    reducers: {
      setLoading: (state, action) => {
        state.loading = action.payload;
      },
      setAppointment: (state, action) => {
        state.appointment = action.payload;
      },
      setError: (state, action) => {
        state.error = action.payload;
      },
      setMessage:(state,action)=>{
        state.message = action.payload;
      }
    },
  });
  export const { setLoading, setAppointment,setError,setMessage } = appointmentDetailReducer.actions;
  export default appointmentDetailReducer.reducer;
