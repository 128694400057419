import React,{useState} from 'react'
import Headeronline from './paycomp/header'
import Discountcode from './paycomp/discountcode'
import Optionpayment from './paycomp/optionpayment'
import Payknow from './paycomp/payknow'
import { FaArrowLeft } from "react-icons/fa";
import { Link,useLocation } from 'react-router-dom'
const Payonline = () => {
  const [active, setActive] = useState(null);
  const location =useLocation();  
  const dayofConfirm = location.state ? location.state.days : '';
  const monthofConfirm = location.state ? location.state.months : '';
  const timeofConfirm = location.state ? location.state.times : '';
  const doctorimage=location.state ? location.state.picturedoctor : '';
  const doctorname=location.state ? location.state.nameofdoctor : '';
  const doctorhospital=location.state ? location.state.nameofhospital : '';
  const doctorfee=location.state ? location.state.feeofdoctor : '';

  return (
      <>
      <div className='bg-[#F8F9FA]'>
      <div className='w-full bg-white border-b-[1px]  block md:hidden py-3'>
        <div className='w-11/12 mx-auto'>
        <div className='flex flex-row items-center space-x-2 text-[15px] mx-auto'>
            <div className='text-black font-semibold'><FaArrowLeft/></div>
            <Link to='/pages/appoinementdetail/'><p className='font-semibold text-black'>Select payment method</p></Link>
          </div>
        </div>
        </div>
      <div className='hidden lg:block'>
      <Headeronline />

      </div>
     <div className='w-full  sm:w-11/12 flex flex-col lg:flex-row  mx-auto my-7 lg:space-x-3'>
     <div className='w-full lg:w-[70%]'>
     <Discountcode/>
     <Optionpayment active={active} setActive={setActive}/>
           </div>  
           <div className='w-full sticky bottom-0 lg:static lg:w-[30%]'>
           <Payknow active={active}
               appointmentDay={dayofConfirm}
               appointmentMonth={monthofConfirm}
               appointmentTime={timeofConfirm}
               doctorimage={doctorimage}
               doctorname={doctorname}
               doctorhospital={doctorhospital}
               doctorfee={doctorfee}
               
                 />
           </div>  
     </div>
      </div>
   
         
      </>
  )
}

export default Payonline
