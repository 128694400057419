import React from 'react'
const Doctorname = ({username,image,fee,address, homeclinic}) => {
  return (
       <>
        <div className=' w-11/12  md:w-4/5 xl:w-3/5   mt-7 mb-8 mx-auto bg-white rounded-md shadow-md px-5 py-8'>
        <div className='flex flex-col'>
           <div className='flex flex-row items-center  space-x-4 border-b-[1px] pb-3'>
             <div className=''>
              <img src={`https://callvet.raabtastore.com/${image}`} alt='img' className='h-auto w-[70px] md:w-[80px] md:h-[80px] xl:w-[60px] xl:h-[60px] rounded-full'/>
             </div>
             <div className='flex flex-col space-y-2 '>
              { username && <h2 className='text-lg sm:text-[22px] text-black leading-5'>{username}</h2> }  
                    <p className='text-black text-sm'>{homeclinic ? '(Home CheckUp)':'(Clinic CheckUp)'}</p>
              {address && <p className='text-sm text-[#686868] '>{address}</p> }
                { fee && <p className='text-sm text-text-[#686868] flex space-x-1'><span>Fee:</span><span className='font-semibold'>Rs.{fee}</span></p> }
             
             </div>
           </div>
         

        </div>
        </div>
       </>
  )
}

export default Doctorname
