import { setAppointmentData, setLoading, setError } from '../reducers/bookappoinmentreducer';
import axios from 'axios';
export const bookAppointment = ({date,doctorId,hospitalAvailableId }) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.post('https://callvet.raabtastore.com/api/doctor/specificHospitalAvailability', {
      date,
      doctorId,
      hospitalAvailableId,
    });
    const hospitals = response.data.data.doctor.hospitals;
    const singleHospital = hospitals.find(hospital => hospital._id === hospitalAvailableId);
    if (singleHospital) {
      dispatch(setAppointmentData({
        ...response.data.data,
        hospitalfee: singleHospital.fee,
        hospitalname: singleHospital.selectHospital.name,
        date,
        doctorId,
        hospitalAvailableId,
        homeclinic: singleHospital.homeCheckUp,
        address: singleHospital.selectHospital.address,
      }));
    } else {
      dispatch(setError('Hospital not found'));
    }
  } catch (error) {
    dispatch(setError(error.response ? error.response.data : 'Network error'));
  } finally {
    dispatch(setLoading(false));
  }
};





