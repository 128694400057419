import React,{useEffect,useState} from 'react'
import Navbar from '../../components/global/navbar'
import Doctorname from '../../components/pages/appoinetment/doctorname'
import Statisfied from '../../components/pages/appoinetment/statisfied'
import LoadingImage from '../../assests/Images/globalpics/loadingimg.svg'
import Bookingtime from '../../components/pages/appoinetment/bookingtime'
import Selectbooknav from '../../components/pages/appoinetment/selectbooknav'
import Footer from '../../components/global/footer'
import Stickyconfirm from '../../components/pages/appoinetment/stickyconfirm'
import { useDispatch,useSelector } from 'react-redux';
import { bookAppointment } from '../../redux/actions/bookappoinmentactions';
import {useParams } from 'react-router-dom'
import Headroom from 'react-headroom'
import {fetchDoctorDetails} from '../../redux/actions/viewprofileaction'
const Appoinetment = () => {
   const dispatch=useDispatch();
   const {ViewPro}=useSelector((state)=>state.view);
   const { doctorid ,hospitalAvailableid }=useParams();
   const {username,image,slots,hospitalfee,hospitalname,homeclinic,address }=useSelector((state)=>state.appointment);
  const [triggerLogin, setTriggerLogin]=useState(false);
  const [mainloading,setMainloading]=useState(false);
  const [selecttime,setSelectime]=useState();
  const [selectmonth,setSelectmonth]=useState(new Date().toLocaleString('en-US', { month: 'short' }));
  const [selectday,setSelectday]=useState(new Date().getDate());
  const [avaibledate, setAvaibledate] = useState(null);
  useEffect(() => {
    dispatch(fetchDoctorDetails(doctorid));
}, [dispatch, doctorid]);
useEffect(() => {
  if (ViewPro && ViewPro.hospitals) {
    console.log('ViewPro:', ViewPro);
    const hospital = ViewPro.hospitals.find(h => h._id === hospitalAvailableid);
    if (hospital) {
      const currentDate = new Date();
      const currentDayIndex = currentDate.getDay(); 
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; 
      const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      for (let i = 0; i < 7; i++) {
        const dayIndex = (currentDayIndex + i) % 7;
        const day = daysOfWeek[dayIndex];
        if (hospital[day]?.status) {
          const selectDay = currentDate.getDate() + i;  
          const formattedDate = `${currentYear}-${currentMonth < 10 ? '0' + currentMonth : currentMonth}-${selectDay < 10 ? '0' + selectDay : selectDay}`;
          setAvaibledate(formattedDate);
          break;
        }
      }
    } else {
      console.log('Hospital not found with id:', hospitalAvailableid);
    }
  }
}, [ViewPro, hospitalAvailableid, selectday]);
  useEffect(() => {
    setMainloading(true); 
    if (doctorid && avaibledate && hospitalAvailableid) {
      setMainloading(true); 
      window.scrollTo(0, 0);
      dispatch(
        bookAppointment({
          date: avaibledate,
          doctorId: doctorid,
          hospitalAvailableId: hospitalAvailableid,
        })
      )
        .then(() => {
          setMainloading(false); 
        })
        .catch((error) => {
          console.error('Error booking appointment:', error);
          setMainloading(false); 
        });
    }
  }, [doctorid, avaibledate, hospitalAvailableid, dispatch]); 
  const handleConfirmClick = () => {
    setTriggerLogin(true);
  };
  return (
      <>
      <div className='bg-[#F8F9FA]'>
      <div className='block md:hidden'>
        <Selectbooknav/>
      </div>
      <Headroom className='z-100' >
      <div className='navshadow  bg-white  hidden md:block  '>
      <Navbar/>
      </div>
      </Headroom>  
        {
          mainloading ?
          (
            <div className='w-full h-screen flex justify-center items-center py-36'>
            <img src={LoadingImage} alt='Loading...' className='w-16 h-16' />
          </div>
          ):(
            <div className='w-full '>
      <Doctorname  username={username} image={image}  fee={hospitalfee}    address={address} homeclinic={homeclinic} />
      <Bookingtime slots={slots}   doctorId={doctorid}   hospitalAvailableId={hospitalAvailableid}  hospitalfee={hospitalfee} hospitalname={hospitalname}   setSelectime={setSelectime} setSelectmonth={setSelectmonth}  setSelectday={setSelectday}  triggerLogin={triggerLogin} 
        avaibledate={avaibledate}
      />
      <Statisfied />
      </div>
          )
        }
      <Footer/>
      <Stickyconfirm selecttime={selecttime}  selectday={selectday} selectmonth={selectmonth}  handleConfirmClick={handleConfirmClick} username={username} image={image} mainloading={mainloading} />
      </div>
      </>
  )
}
export default Appoinetment
