import { createSlice } from "@reduxjs/toolkit";
const PaycashReducer=createSlice({
name:'PayCash',
initialState:{
   PaycashItems:[],
   loading:false,
   error:false,
},
reducers:{
    setPayCash(state,action){
        state.PaycashItems=action.payload;
    },
    setLoading(state,action){
        state.loading=action.payload;
    },
    setError(state,action){
        state.error=action.payload;
    }
}
})
export const{setPayCash,setLoading,setError}=PaycashReducer.actions;
export default PaycashReducer.reducer;
