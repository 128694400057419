import React,{useState,useEffect,useContext} from 'react';
import { RxCross2 } from 'react-icons/rx';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
// import Image from '../../../../assests/Images/doctorlistsvg/image 120.svg'
import { FaRegClock } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
// import { FaApple } from "react-icons/fa";
// import { FcGoogle } from "react-icons/fc";
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {enterLogin} from '../../../../redux/actions/loginotpaction';
import {verifyLogin} from '../../../../redux/actions/verfiyloginraction'
import { ToastContainer, toast } from 'react-toastify';
import { PatientContext } from '../../../../context/pateintcontext'


  import 'react-toastify/dist/ReactToastify.css';
import './login.css'
const Login = ({onClose}) => {
  const navigate=useNavigate();
  const dispatch=useDispatch();
    const [PhoneNumber,setPhoneNumber]=useState('');
    const {setSelectedPatient} = useContext(PatientContext);
    const [btndesign,setBtndesign]=useState(false);
    const [btnverify,setBtnverify]=useState(false);
    const [display,setDisplay]=useState(true);
    const [displaysecond,setDisplaySecond]=useState(false);
    const [vaild,setVaild]=useState(true);
    const [otp, setOtp]=useState('');
    const{error:otpLoginError,success: otpLoginSuccess }=useSelector((state)=>state.otplogin);
    const {error:verifyLoginError,success:verifyLoginSuccess}=useSelector((state)=>state.userverfied);
    const handlechange=(value)=>{
          const fullName=`+${value}`
        setPhoneNumber(fullName);
        setVaild(vaildatePhoneNumber(value));
    }
   const vaildatePhoneNumber=(PhoneNumber)=>{
        const PhonenumberPattern=/^\d{12}$/;
        return PhonenumberPattern.test(PhoneNumber)
   }
   useEffect(() => {
    if (verifyLoginError) {
      toast.error('OTP is invalid!', {
        position: "top-center",
      });
    }
  }, [verifyLoginError]);

  useEffect(() => {
    if (verifyLoginSuccess) {
      toast.success('Your OTP is verified!', {
        position: "top-center",
      });
    }
  }, [verifyLoginSuccess, navigate, PhoneNumber]);
   const handleSubmit = (e) => {
    e.preventDefault();
    if (vaild) {
      dispatch(enterLogin(PhoneNumber));
      setBtndesign(true);
      setTimeout(() => {
        setBtndesign(false);
      }, 10000); 
    }
  };
  const handleVerifyLogin = (e) => {
    e.preventDefault();
    dispatch(verifyLogin(PhoneNumber, otp));
    const storedAuthData = localStorage.getItem('authData');
    const authData = storedAuthData ? JSON.parse(storedAuthData) : null;
    if (authData && authData.user && authData.user.username && authData.user.phone === PhoneNumber) {
      setSelectedPatient(authData.user.username);
      console.log('show data', authData.user.username);
    setBtnverify(true);
    setTimeout(() => {
      setDisplay(false)
      setDisplaySecond(false);
    }, 5000); 
  }
  }
  useEffect(()=>{
    if(otpLoginError){
      setDisplay(true);
      toast.error('OTP Not sent!', {
        position: "top-center",
        })
    }
  },[otpLoginError])
  const handleclose=()=>{
    setDisplay(false)
    setDisplaySecond(false)
    onClose();
  }
  useEffect(() => {
    if (otpLoginSuccess) {
      setDisplaySecond(true);
      toast.success('OTP successfully sent!', {
        position: "top-center",
        })
    }
  }, [otpLoginSuccess]);
  return (
    <>{
        display &&
        (
            <div className='w-full h-screen fixed top-0 left-0 z-40 ' style={{backgroundColor:'rgba(0, 0, 0, 0.2)'}}>
        <div className='  h-screen  m-10 w-full md:w-[469px] mx-auto  my-0 md:my-3 bg-white  sm:overflow-y-auto  md:h-[590px]  rounded-none md:rounded-md relative'>
          <div className='py-1 md:py-2 rounded-tl-md rounded-tr-md cursor-pointer flex justify-end px-3'>
            <RxCross2 onClick={handleclose} />
          </div>
          <div className='text-center text-[24px] text-[#232426] font-medium pt-1 md:pt-10 sm:pb-0 md:pb-2'>
             Enter your Phone Number
             </div>
             <div className='text-sm text-center  pt-1 md:pt-4  text-[#46484B] px-6'>
             Please provide your mobile number to login or register.
             </div>
             <form onSubmit={handleSubmit}>
          <div className='pt-1  md:pt-3 w-11/12   mx-auto flex  justify-center'>
            <PhoneInput type='text' country={'pk'} placeholder='Enter Your Phone Number'  value={PhoneNumber}  onChange={handlechange} inputProps={{required:true,}}  
             inputClass="custom-change " 
      buttonClass="bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
            />
            </div>
    {!vaild && <p className='text-xs text-red-900 px-5 pt-1'>Please provide mobile number </p>}
    <div className='absolute bottom-[70px] sm:bottom-6 md:bottom-9  w-full  flex justify-center left-0'>
             <button type='submit' className='w-11/12  bg-[--primary] text-white mx-auto  flex justify-center items-center space-x-2 text-[15px] py-2 rounded-md'  >
             { btndesign ? (
  <div className="loader"></div>
) : (
  <>
    <span>Continue</span>
    <span><FaArrowRightLong /></span>
  </>
)}             </button>
      </div>
          </form>
        </div>
      </div>
        )
    }
    {
      displaysecond  && (
        <div className='w-full h-screen fixed top-0 left-0 z-50 ' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                        <div className='h-screen  m-10 w-full md:w-[469px] mx-auto  my-0 md:my-3 bg-white  sm:overflow-y-auto  md:h-[590px]  rounded-none md:rounded-md relative'>

                            <div className=' flex justify-end py-2 px-3 rounded-tl-md rounded-tr-md'>
                            <button onClick={handleclose} ><RxCross2/></button>
                            </div>
                            <div className='text-center text-[24px] text-[#232426] font-medium pt-10  '>
                            Verify OTP 
                           </div>
                           <form onSubmit={handleVerifyLogin}>
                           <div className='text-[15px]  pt-2  text-center text-[#686868] px-6'>
                                The number you entered: <span >{PhoneNumber} (edit)</span>
                            </div>
                            <div className='flex justify-center my-4'>

      <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span>  </span>}
      renderInput={(props) => <input {...props} 
      style={{width:'39px',
      height:'40px',
      margin:'0 5px',
      padding:'10px',
      border:'1px solid #ccc',
      borderRadius:'5px',
      textAlign:'center'
      
      }}
       />}
     
    />

                            </div>

                    <div className='absolute bottom-[120px] sm:bottom-[70px] md:bottom-[80px] w-full  flex px-4 sm:px-8 md:px-5'>
                    <div class="flex items-center space-x-2">
    <input checked id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-[#4C63BD] bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
    <label for="checked-checkbox" class="ms-2 text-sm font-normal text-black">Remember me</label>
</div>
</div>
<div className='absolute bottom-[70px] sm:bottom-6 md:bottom-9  w-full  flex justify-center left-0'>
<button type='submit' className='w-11/12  bg-[--primary] text-white mx-auto  flex justify-center items-center space-x-2 text-[15px] py-2 rounded-md'  >
{ btnverify ? (
  <div className="loader"></div>
) : (
  <>
  <span>Verify & Login</span> <span><FaArrowRightLong /></span>
  </>
)}  

</button>
</div>




                           </form>
                      
                        </div>
                    
                </div>
      )
    }
    <ToastContainer/>
     
    </>
  );
};

export default Login;
