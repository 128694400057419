import React,{useState,useEffect} from 'react';
import { RxCross2 } from 'react-icons/rx';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
// import Image from '../../../../assests/Images/doctorlistsvg/image 120.svg'
import { FaRegClock } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
// import { FaApple } from "react-icons/fa";
// import { FcGoogle } from "react-icons/fc";
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {enterLogin} from '../../../../redux/actions/loginotpaction';
import {verifyLogin} from '../../../../redux/actions/verfiyloginraction'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import './login.css'
const Login = ({time,daybooking,monthbooking,onClose}) => {
  const navigate=useNavigate();
  const dispatch=useDispatch();
    const [PhoneNumber,setPhoneNumber]=useState('');
    const [btndesign,setBtndesign]=useState(false);
    const [btnverify,setBtnverify]=useState(false);
    const [display,setDisplay]=useState(true);
    const [displaysecond,setDisplaySecond]=useState(false);
    const [vaild,setVaild]=useState(true);
    const [otp, setOtp]=useState('');
    const {username,image,hospitalfee,hospitalname,homeclinic,doctorId,hospitalAvailableId,date,address,bio }=useSelector((state)=>state.appointment);
     console.log(bio)
    const{error:otpLoginError,success: otpLoginSuccess }=useSelector((state)=>state.otplogin);
    const {error:verifyLoginError,success:verifyLoginSuccess}=useSelector((state)=>state.userverfied);
    const handlechange=(value)=>{
          const fullName=`+${value}`
        setPhoneNumber(fullName);
        setVaild(vaildatePhoneNumber(value));
    }
   const vaildatePhoneNumber=(PhoneNumber)=>{
        const PhonenumberPattern=/^\d{12}$/;
        return PhonenumberPattern.test(PhoneNumber)
   }
   useEffect(() => {
    if (verifyLoginError) {
      toast.error('OTP is invalid!', {
        position: "top-center",
      });
    }
  }, [verifyLoginError]);

  useEffect(() => {
    if (verifyLoginSuccess) {
      toast.success('Your OTP is verified!', {
        position: "top-center",
      });
      navigate('/appointmentdetail', {
        state: {
          PatientNumber:PhoneNumber,
          mon:monthbooking,
          day:daybooking,
          time:time,
          doctorname:username,
          doctorimage:image,
          fee:hospitalfee,
          hospitalname:hospitalname,
          homecheckup:homeclinic,
          bookingappionment:date,
          hospitalid:hospitalAvailableId,
          doctorId:doctorId,
          hospitaladress:address,
          doctorbio:bio,
        }
      });
    }
  }, [verifyLoginSuccess, navigate, PhoneNumber, monthbooking, daybooking, time,username,image,hospitalfee,hospitalname,homeclinic,date,hospitalAvailableId,doctorId,address,bio]);
   const handleSubmit = (e) => {
    e.preventDefault();
    if (vaild) {
      dispatch(enterLogin(PhoneNumber));
      setBtndesign(true);
      setTimeout(() => {
        setBtndesign(false);
      }, 10000); 
    }
  };
  const handleVerifyLogin = (e) => {
    e.preventDefault();
    dispatch(verifyLogin(PhoneNumber, otp));
    setBtnverify(true);
    
    setTimeout(() => {
      setBtnverify(false);
    }, 10000); 
  };
  useEffect(()=>{
    if(otpLoginError){
      setDisplay(true);
      toast.error('OTP Not sent!', {
        position: "top-center",
        })
    }
  },[otpLoginError])
  const handleclose=()=>{
    setDisplay(false)
    setDisplaySecond(false)
    onClose();
  }
  useEffect(() => {
    if (otpLoginSuccess) {
      setDisplaySecond(true);
      toast.success('OTP successfully sent!', {
        position: "top-center",
        })
    }
  }, [otpLoginSuccess]);
  return (
    <>{
        display &&
        (
            <div className='w-full h-screen fixed top-0 left-0 z-40 ' style={{backgroundColor:'rgba(0, 0, 0, 0.2)'}}>
        <div className='  h-screen  m-10 w-full md:w-[469px] mx-auto  my-0 md:my-3 bg-white  sm:overflow-y-auto  md:h-[590px]  rounded-none md:rounded-md relative'>
          <div className='py-1 md:py-2 rounded-tl-md rounded-tr-md cursor-pointer flex justify-end px-3'>
            <RxCross2 onClick={handleclose} />
          </div>
          <div className='text-center text-[24px] text-[#232426] font-medium pt-1 md:pt-10 sm:pb-0 md:pb-2'>
             Enter your Phone Number
             </div>
             <div className='text-sm text-center  pt-1 md:pt-4  text-[#46484B] px-6'>
             Please provide your mobile number to login or register.
             </div>
             <form onSubmit={handleSubmit}>
          <div className='pt-1  md:pt-3 w-11/12   mx-auto flex  justify-center'>
            <PhoneInput type='text' country={'pk'} placeholder='Enter Your Phone Number'  value={PhoneNumber}  onChange={handlechange} inputProps={{required:true,}}  
             inputClass="custom-change " 
      buttonClass="bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
            />
            </div>
    {!vaild && <p className='text-xs text-red-900 px-5 pt-1'>Please provide mobile number </p>}

    <div className='flex flex-col  space-y-2  my-4 px-2'>
          <div className='text-[#46484B] font-semibold text-base'>
          Your appointment
          </div>
          <div className='flex flex-row justify-between '>
           <div className='flex items-center space-x-3 '>
             <img src={`https://callvet.raabtastore.com/${image}`} alt='img' className='h-10 w-10 rounded-full'/>
            {username && <p className='text-sm text-[#686868]'>{username}</p>}
           </div>
           <div className='flex items-center space-x-2 text-[#686868]'>
              <div><FaRegClock/></div>
             <p className="text-sm text-[#686868]">{monthbooking} {daybooking} {time}</p> 
           </div>
          </div>
          </div>

         
    <div className='absolute bottom-[60px] sm:bottom-6 md:bottom-9  w-full  flex justify-center left-0'>
             <button type='submit' className='w-11/12  bg-[--primary] text-white mx-auto  flex justify-center items-center space-x-2 text-[15px] py-2 rounded-md'  >
             { btndesign ? (
  <div className="loader"></div>
) : (
  <>
    <span>Continue</span>
    <span><FaArrowRightLong /></span>
  </>
)}             </button>
      </div>
          </form>
        </div>
      </div>
        )
    }
    {
      displaysecond  && (
        <div className='w-full h-screen fixed top-0 left-0 z-50 ' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                        <div className='h-screen  m-10 w-full md:w-[469px] mx-auto  my-0 md:my-3 bg-white  sm:overflow-y-auto  md:h-[590px]  rounded-none md:rounded-md relative'>

                            <div className=' flex justify-end py-2 px-3 rounded-tl-md rounded-tr-md'>
                            <button onClick={handleclose} ><RxCross2/></button>
                            </div>
                            <div className='text-center text-[24px] text-[#232426] font-medium pt-10  '>
                            Verify OTP 
                           </div>
                           <form onSubmit={handleVerifyLogin}>
                           <div className='text-[15px]  pt-2  text-center text-[#686868] px-6'>
                                The number you entered: <span >{PhoneNumber} (edit)</span>
                            </div>
                            <div className='flex justify-center my-4'>

      <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span>  </span>}
      renderInput={(props) => <input {...props} 
      style={{width:'39px',
      height:'40px',
      margin:'0 5px',
      padding:'10px',
      border:'1px solid #ccc',
      borderRadius:'5px',
      textAlign:'center'
      
      }}
       />}
     
    />

                            </div>

                    <div className='absolute bottom-[120px] sm:bottom-[70px] md:bottom-[80px] w-full  flex px-4 sm:px-8 md:px-5'>
                    <div class="flex items-center space-x-2">
    <input checked id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-[#4C63BD] bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
    <label for="checked-checkbox" class="ms-2 text-sm font-normal text-black">Remember me</label>
</div>
</div>
<div className='absolute bottom-[60px] sm:bottom-6 md:bottom-9  w-full  flex justify-center left-0'>
<button type='submit' className='w-11/12  bg-[--primary] text-white mx-auto  flex justify-center items-center space-x-2 text-[15px] py-2 rounded-md'  >
{ btnverify ? (
  <div className="loader"></div>
) : (
  <>
  <span>Verify & Login</span> <span><FaArrowRightLong /></span>
  </>
)}  
{/* <span>Verify & Login</span> <span><FaArrowRightLong /></span> */}

</button>
</div>




                           </form>
                      
                        </div>
                    
                </div>
      )
    }
    <ToastContainer/>
     
    </>
  );
};

export default Login;
