import React from 'react'
import { useNavigate } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { GoDotFill } from "react-icons/go";
import './doctor.css'
import { IoIosArrowForward } from "react-icons/io";
// import  {bookAppointment} from '../../../redux/actions/bookappoinmentactions'
import { useDispatch } from 'react-redux';
import { IoIosArrowBack } from "react-icons/io";
const Doctorlist = ({ username, yearofexperince, pictue, doctorId, doctordetails, hospital}) => {
     console.log('this is doctorid number :',doctorId);
    // const dispatch =useDispatch();
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 767 },
          items: 2,
          slidesToSlide: 1 
        },
        mobile: {
          breakpoint: { max: 767, min: 500 },
          items: 2,
          slidesToSlide: 1 
        },
        smallscreen:{
          breakpoint: { max: 500, min:0 },
          items: 1,
          slidesToSlide: 1 
        },
      };

 const CustomLeftArrow = ({ onClick}) => {
        return (
          <button onClick={onClick} className='absolute top-8 md:top-12 lg:top-10 xl:top-14  left-2' >
            <IoIosArrowBack className='text-black  font-bold text-lg' />
          </button>
        );
      };
      const CustomRightArrow = ({ onClick }) => {
        return (
          <button onClick={onClick} className='absolute top-8 md:top-12 lg:top-10 xl:top-14  right-2 '  >
            <IoIosArrowForward className='text-black font-bold text-lg' />
          </button>
        );
      };
    const navigate = useNavigate();
    console.log(doctorId._id)
    const handleViewProfile = (profileid) => {
        navigate(`/doctor/${profileid}`);
    };
 const currentDate =new Date();
 const year=currentDate.getFullYear();
 const month=String(currentDate.getMonth()+1).padStart(2,0);
const day=String(currentDate.getDay()).padStart(2,0);
const currentdate=`${year}-${month}-${day}`;
 const generateNext7Dates = () => {
    const dates = [];
    const currentDate = new Date();
    for (let i = 0; i < 7; i++) {
      const futureDate = new Date(currentDate);
      futureDate.setDate(currentDate.getDate() + i);
      const year = futureDate.getFullYear();
      const month = String(futureDate.getMonth() + 1).padStart(2, '0');
      const day = String(futureDate.getDate()).padStart(2, '0');
      dates.push(`${year}-${month}-${day}`);
    }
    return dates;
  };
  
  const getAvailabilityMessage = (hospitaldetail) => {
    const daysOfWeek = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday'
    ];
    const today = new Date().getDay(); 
    const dates = generateNext7Dates();
    if (hospitaldetail[daysOfWeek[today]].status) {
      return { message: 'Available today', date: dates[0] };
    }
    const tomorrow = (today + 1) % 7;
    if (hospitaldetail[daysOfWeek[tomorrow]].status) {
      return { message: 'Available tomorrow', date: dates[1] };
    }
    for (let i = 2; i < 7; i++) {
      const dayIndex = (today + i) % 7;
      const dayName = daysOfWeek[dayIndex];
      const dayDetail = hospitaldetail[dayName];
      if (dayDetail.status) {
        return {
          message: `Available on ${new Date(dates[i]).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric'
          })}`,
          date: dates[i]
        };
      }
    }
    return { message: 'No availability this week', date: null };
  };
  const handleBookAppointment = (hospitaldetail) => {
    navigate(`/appoinetment/${doctorId}/${hospitaldetail._id}`); 
};
const firsthospitaldetal=hospital[0];
    return (
        <>
            <div className='w-full flex flex-col space-y-10 px-4 md:px-0 py-7 bg-[#F8F9FA]'>
                <div className='flex flex-col w-full md:w-5/6 mx-auto bg-white px-2 sm:px-10 py-10 shadow-md rounded-md relative '>
                  
                    <div className='flex flex-row justify-between w-full lg:w-auto '>
                        <div className='flex flex-row space-x-5 sm:space-x-7'>
                            <img src={`https://callvet.raabtastore.com/${pictue}`} alt='img' className='w-20 h-20 sm:w-[100px] sm:h-[100px] mt-3 rounded-full cursor-pointer' />
                            <div className='flex flex-col space-y-1 ml-5'>
                                    <div className='text-lg sm:text-xl font-semibold text-black pt-8 sm:pt-2 leading-6 sm:leading-9 ' onClick={() => handleViewProfile(doctorId)}>
                                        {username}
                                    </div>
                                
                                <div className='text-[11px] sm:text-sm'>
                                    {doctordetails}
                                </div>
                                <div className='hidden sm:block'>
                                <div className='flex flex-row  py-2 mt-4'>
                                    <div className='flex flex-col border-r-0 sm:border-r-[1px] pl-3 pr-3'>
                                        <div className='text-xs text-[rgb(104,104,104)] font-normal'>Wait Time</div>
                                        <div className='text-sm font-semibold text-black'>0</div>
                                    </div>
                                    <div className='flex flex-col border-r-0 sm:border-r-[1px] pl-3 pr-3'>
                                        <div className='text-xs text-[#686868] font-normal'>Experience</div>
                                        <div className='text-sm font-semibold text-black'>{yearofexperince}</div>
                                    </div>
                                    <div className='flex flex-col pl-3 pr-3'>
                                        <div className='text-xs text-[#686868] font-normal'>Satisfied Patients</div>
                                        <div className='text-sm font-semibold text-black'>0</div>
                                    </div>
                                </div>

                                </div>
                                
                            </div>

                        </div>
                      
                        {/* Buttons for large screens */}
                        <div className='hidden lg:block'>
                            <div className='flex flex-col space-y-3 mt-3'>
                                <button className='w-56 h-12 border border-[--primary] text-[--primary] text-sm rounded hover:bg-[--primary] hover:text-white transition-all duration-3000' onClick={() => handleViewProfile(doctorId)}>
                                    View Profile
                                </button>
                         {
                            firsthospitaldetal && (
                                <button className='text-center bg-[--primary] text-white w-56 h-12 rounded text-sm box-shadow'  onClick={() => handleBookAppointment(firsthospitaldetal)} >
                                 Book Appointment
                                  </button>
                            )
                         }                                 
                      
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-auto  block sm:hidden'>
                    <div className='flex flex-row justify-center  py-2 mt-4'>
                                    <div className='flex flex-col border-r-[1px] pl-3 pr-3'>
                                        <div className='text-xs text-[rgb(104,104,104)] font-normal'>Wait Time</div>
                                        <div className='text-sm font-semibold text-black'>0</div>
                                    </div>
                                    <div className='flex flex-col border-r-[1px] pl-3 pr-3'>
                                        <div className='text-xs text-[#686868] font-normal'>Experience</div>
                                        <div className='text-sm font-semibold text-black'>{yearofexperince}</div>
                                    </div>
                                    <div className='flex flex-col  pl-3 pr-3'>
                                        <div className='text-xs text-[#686868] font-normal'>Satisfied Patients</div>
                                        <div className='text-sm font-semibold text-black'>0</div>
                                    </div>
                                </div>
                    </div>
                 
                    
                    {/* Appointment section */}
                    <Carousel 
                     axis='horizontal'
              showStatus={false}
              responsive={responsive}
              customRightArrow={<CustomRightArrow />}
              customLeftArrow={<CustomLeftArrow />}
                    className='w-full mx-auto     h-auto  cursor-pointer flex   my-6  bg-white  '>
                         {hospital.map((hospitaldetail) => {
        const availability = getAvailabilityMessage(hospitaldetail);

        return (
          <div key={hospitaldetail._id} className="flex m-1 h-[85px] rounded-md relative border      ">
            <div
              className="flex flex-col rounded-md space-y-2"
              onClick={() => handleBookAppointment(hospitaldetail, availability.date)}
            >
              <div className="text-[13px] xl:text-sm text-black font-medium px-2 pt-2">
                Appointment at {hospitaldetail.selectHospital.name}
                <span>{hospitaldetail.homeCheckUp ? '(Home CheckUp)' : '(Clinic)'}</span>
              </div>
              <div className="absolute bottom-2 w-full">
                <div className="flex flex-row justify-between px-2 ">
                  <div className="text-[#28B446] text-sm flex flex-row items-center space-x-2">
                    <span><GoDotFill /></span><span>{availability.message}</span>
                  </div>
                  <div className="text-black text-opacity-90 text-[13px] font-semibold">Rs{hospitaldetail.fee}</div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
                    </Carousel>
                    {/* Buttons for small screens */}
                    <div className='block lg:hidden mt-5'>
                        <div className='flex flex-row space-x-2'>
                                <button className='w-1/2 h-11 text-[--primary] border border-[--primary] text-[15px] rounded p-3 hover:bg-[--primary] hover:text-white transition-all duration-3000' onClick={() => handleViewProfile(doctorId)}>
                                    View Profile
                                </button>
                              {
                                firsthospitaldetal && (
                                    <button className='text-center text-white bg-[--primary] text-[15px] w-1/2 h-11 rounded p-3 hover:bg-opacity-85 transition-all duration-3000 box-shadow' onClick={() => handleBookAppointment(firsthospitaldetal,currentdate)}>
                                    Book Appointment
                                </button>
                                )
                              
                              }
                              
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Doctorlist;
