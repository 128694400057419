import React, { useState } from 'react'
import Avaliable from '../../../assests/Images/Svg/Purple Illustrated Online Doctor Instagram Post 1.svg';
import Clink from '../../../assests/Images/Svg/Green Minimalist Aesthetic Visit Website Mockup Instagram Post (2) 1.svg'
import Homes from '../../../assests/Images/Svg/Blue Modern Online Doctor Consultations Facebook Post 1.svg'
import { IoIosArrowForward } from "react-icons/io";
import Selectoption from './selectoption';
const Booking = () => {
  const [popupshow,setPopupshow]=useState(false);
const handlepopup=(index)=>{
  setPopupshow(index)
}
const handleclose=()=>{
  setPopupshow(null)
}
  return (
     <>
      <div className='w-full my-4 relative '>
         <div className='w-11/12 mx-auto py-5 '>
         <div className='text-start leading-[39px] text-2xl sm:text-[26px]  py-5   text-black font-semibold'>
         I Want to Book
         </div>
           <div className='grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 gap-4  lg:gap-2 xl:gap-3 w-full py-4  '>
           {/* Online Consultancy */}
                <div className=' flex flex-row  h-[178px]  border box-shadow  rounded cursor-pointer relative'>
                  <div className='px-3  text-white text-xs py-1 rounded-tl rounded-br bg-[#28B446] absolute'>
                  Available Now
                  </div>
                  <div className='bg-gradient-to-r from-[#4C63BD] to-[#2C67F2] w-[45%] flex items-center justify-center rounded-tl rounded-bl'>
                  <img src={Avaliable} alt='img' className=' '/>
                  </div>
                    <div className=' flex flex-col  px-2 py-4 relative w-[55%]'>
                    <div className='text-base leading-5 font-bold   my-1'>
                    Online Video Call Consultancy
                    </div>
                    <div className='text-sm text-start  text-[#686868]  my-1 '>
                    Video Consultations with Top Specialists in Rs. 1000                   
                     </div>
                    <div className='my-1 flex  absolute bottom-2 w-full'>
                        <buttton className='bg-[#4C63BD] text-white    w-11/12 text-center text-sm flex justify-center space-x-3 items-center py-1 rounded font-semibold '><span>Start Consulting</span><span><IoIosArrowForward/></span></buttton>
                    </div>
                    <div>

                    </div>
                    </div>
                </div>
                {/* Clinic Checkup */}
                <div className='  flex flex-row h-[178px] border box-shadow rounded cursor-pointer'  onClick={()=>handlepopup(1)} >
                  <div className='bg-[rgb(255,255,127)] w-[45%] flex items-center justify-center rounded-tl rounded-bl'>
                   <img src={Clink} alt='img' />
                  </div>
                    <div className='flex flex-col  px-2 py-4 relative w-[55%] '>
                    <div className='text-base leading-5 font-bold   my-1'>
                    Clinic Checkup
                    </div>
                    <div className='text-sm text-start  text-[#686868] my-1  '>
                    Video Consultations with Top Specialists in Rs. 1000 
                    </div>
                    <div className='my-1 flex absolute bottom-2 w-full'>
                        <buttton className='bg-[rgb(255,255,127)]  text-black   w-11/12 text-center text-sm flex justify-center space-x-3 items-center py-1 rounded font-semibold '><span>Clinic Checkup</span><span><IoIosArrowForward/></span></buttton>
                    </div>
                    <div>

                    </div>
                    </div>
                </div>
             {/* Home Checkup */}
                <div className=' flex flex-row h-[178px] border  box-shadow  rounded cursor-pointer '  onClick={()=>handlepopup(2)}>
                  <div className='bg-[#80CAFA] w-[45%] flex items-center justify-center rounded-tl rounded-bl'>
                   <img src={Homes} alt='img' />
                  </div>
                    <div className='flex flex-col px-2 py-4 relative w-[55%]'>
                    <div className='text-base leading-5 font-bold   my-1'>
                    Home Checkup
                    </div>
                    <div className='text-sm text-start text-[#686868]  my-1'>
                    Video Consultations with Top Specialists in Rs. 1000 
                    </div>
                    <div className='my-1 flex justify-start absolute bottom-2 w-full '>
                        <buttton className='bg-[#80CAFA] text-white  w-11/12
                         text-center text-sm flex justify-center space-x-3 items-center py-1 rounded  font-semibold  '><span>Home Checkup</span><span><IoIosArrowForward/></span></buttton>
                    </div>
                    <div>

                    </div>
                    </div>
                </div>
          </div>
         </div>
{/* Render Popup based on selection */}
{popupshow===1 ? <Selectoption handleclose={handleclose}/>:''}
{popupshow===2 ? <Selectoption handleclose={handleclose}/>:''}
      </div>
     </>
  )
}

export default Booking
