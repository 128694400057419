import React from 'react'

const Eduction = ({edu}) => {
  return (
    <>
    <div className='w-full p-5  lg:pt-5  md:pb-10 box  rounded-lg lg:shadow-none lg:rounded-none mt-7 lg:border-b-2 bg-white '>
       <div className='flex flex-col space-y-4 px-1  '>
         <div className='flex flex-row space-x-2 items-center '>
           <div className='text-[17px] font-semibold text-black   leading-10'>Education</div> <div className='bg-[#EFF0F2] text-[10px] flex items-center  rounded-3xl h-4 px-1'>Rvmp Verified</div>
         </div>
         <div className=' w-full '>
         <ul className='flex flex-col space-y-3 sm:space-y-0 lg:space-y-0  sm:flex-row sm:flex-wrap text-sm px-4'>
           {
             edu && edu.map((education)=>(
              <li className='sm:w-3/6 lg:w-full xl:w-3/6 list-disc' key={education._id} >
                  {education.institution}
              </li>
            ))
           }
            
       

         {/* <li className='sm:w-3/6 lg:w-full xl:w-3/6 list-disc	'>
         M.B.B.S - Punjab University, Lahore
         </li>
         <li className='sm:w-3/6 lg:w-full xl:w-3/6 list-disc	'>
         F.C.P.S (Dermatology) - College of Physicians and Surgeons, Pakistan
         </li> */}
         
        
         </ul>
        
         </div>

       </div>
    </div>
      
    </>
  )
}

export default Eduction
