import React, { useState, useEffect } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import Whichtime from './whichtime';
import Stickyconfirm from './stickyconfirm';
import './booking.css';
import { bookAppointment } from '../../../redux/actions/bookappoinmentactions';
import { useDispatch } from 'react-redux';
import Loadingimage from '../../../assests/Images/globalpics/loadingimg.svg';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1 
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 3,
    slidesToSlide: 1 
  },
  mobile: {
    breakpoint: { max: 767, min: 500 },
    items: 3,
    slidesToSlide: 1 
  },
  smallscreen: {
    breakpoint: { max: 500, min: 0 },
    items: 2,
    slidesToSlide: 1 
  }
};

const CustomLeftArrow = ({ onClick }) => {   
  return (
    <button onClick={onClick} className='absolute top-7 left-0 z-20 flex justify-center items-center'>
      <IoIosArrowBack className='text-[#46484B] font-bold text-xl' />
    </button>
  );
};

const CustomRightArrow = ({ onClick }) => {
  return (
    <button onClick={onClick} className='absolute top-7 right-0 z-20 flex justify-center items-center'>
      <IoIosArrowForward className='text-[#46484B] font-bold text-xl' />
    </button>
  );
};

const Bookingtime = ({ slots, doctorId, hospitalAvailableId, hospitalfee, hospitalname, setSelectime, setSelectmonth, setSelectday, triggerLogin, avaibledate }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(0);
  const [localLoading, setLocalLoading] = useState(false);

  // Filter days starting from avaibledate
  const days = [];
  const today = new Date();
  const startDate = avaibledate ? new Date(avaibledate) : new Date();
  for (let i = 0; i < 14; i++) {
    const day = new Date(startDate.getTime() + (i * 24 * 60 * 60 * 1000));
    const dayName = day.toLocaleString('en-US', { weekday: 'short' });
    const dayDate = day.getDate();
    const dayMonth = day.toLocaleString('en-US', { month: 'short' });
    days.push({ dayName, dayDate, dayMonth });
  }

  useEffect(() => {
    // Ensure the Carousel starts from the index corresponding to avaibledate
    if (avaibledate) {
      setShow(0); // Start from the beginning of the filtered days
    }
  }, [avaibledate]);

  const handleclick = (index) => {
    setLocalLoading(true);
    const selectedDate = new Date(startDate.getTime() + (index * 24 * 60 * 60 * 1000));
    const formattedDate = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
    setShow(index);
    dispatch(bookAppointment({ 
      date: formattedDate, 
      doctorId, 
      hospitalAvailableId, 
      hospitalfee, 
      hospitalname 
    }))
    .then(() => {
      setLocalLoading(false); 
    })
    .catch(() => {
      setLocalLoading(false);
    });
    setSelectmonth(days[index].dayMonth);
    setSelectday(days[index].dayDate);
  };

  const goToNextDay = () => {
    const nextIndex = (show + 1) % days.length;
    handleclick(nextIndex);
  };

  const nextDayIndex = (show + 1) % days.length;
  const nextDays = days[nextDayIndex];

  return (
    <>
      <div className='w-11/12 md:w-4/5 xl:w-3/5 rounded-md mb-8 mx-auto bg-white shadow-md px-5 py-5'>
        <Carousel 
          axis='horizontal'
          showStatus={false}
          responsive={responsive}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          infinite={false}
          selectedItem={show}
          className='w-full mx-auto h-20 cursor-pointer flex'
        >
          {days.map((day, index) => (
            <div
              key={index}
              className={`text-center flex flex-col pt-3 w-28 mx-5 sm:w-40 h-16 sm:mx-4 text-sm ${index === show ? 'actives' : ''}`}
              onClick={() => handleclick(index)}
            >
              <p>{index === 0 ? (startDate.toDateString() === today.toDateString() ? 'Today' : day.dayName) : (index === 1 ? (startDate.toDateString() === today.toDateString() ? 'Tomorrow' : day.dayName) : day.dayName)}</p>
              <h1>
                {`${day.dayDate} ${day.dayMonth}`}
              </h1> 
            </div>
          ))}
        </Carousel>

        {localLoading ? (
          <div className='w-full flex justify-center items-center py-36'>
            <img src={Loadingimage} alt='img' className='w-16 h-16'/>
          </div>
        ) : (show !== null && (
          <Whichtime
            daybooking={days[show].dayDate}
            monthbooking={days[show].dayMonth}
            slots={slots}
            days={days}
            handleclick={handleclick}
            setSelectime={setSelectime}
            triggerLogin={triggerLogin}
            goToNextDay={goToNextDay}
            dayname={nextDays.dayName}
            daydate={nextDays.dayDate}
            daymonth={nextDays.dayMonth}
          />
        ))}
      </div>
    </>
  );
};

export default Bookingtime;
