import React, { useContext, useEffect, useState } from 'react';
import Logo from '../../../assests/Images/globalpics/Layer 1.svg';
import './navbar.css';
import Responsivenavbar from './responsivenavbar';
import { FaPhoneAlt } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchapiservices } from '../../../redux/actions/servicesaction';
import { fetchapicities } from '../../../redux/actions/citylistaction';
import Loadingimage from '../../../assests/Images/globalpics/loadingimg.svg'
import Login from './navbarotp/login';
import { PatientContext } from '../../../context/pateintcontext';

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ServiesDvm } = useSelector((state) => state.services);
  const { CitySelect } = useSelector((state) => state.city);
  const { selectedPatient, setSelectedPatient } = useContext(PatientContext);
  const [membershow, setMembershow] = useState(false);
  const [show, setShow] = useState(false);
  const [listshow, setListshow] = useState(null);
  const [displayPopup, setDisplayPopup] = useState(false);

  // Fetch services and cities on component mount
  useEffect(() => {
    dispatch(fetchapiservices());
    dispatch(fetchapicities());
  }, [dispatch]);

  // Set selected patient from localStorage on initial mount
  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('authData'));
    if (authData && authData.token && authData.user) {
      setSelectedPatient(authData.user.username);
    }
  }, [setSelectedPatient]);

  // Handle opening login popup
  const handleLoginOTP = () => {
    console.error("User not found. Displaying popup and navigating to home.");
    setDisplayPopup(true);
  };

  // Handle closing login popup
  const handleLoginClose = () => {
    setDisplayPopup(false);
  };

  // Logout user
  const logoutUser = () => {
    localStorage.clear();
    setSelectedPatient(null);
  };

  // Handle navigation to doctor category
  const handleCategoryClick = (city, category) => {
    navigate(`/doctors/${city}/${category}`);
  };

  // Toggle show dropdown for doctor categories
  const handleShow = () => {
    setShow(!show);
  };

  // Toggle member dropdown
  const handleUserMenu = () => {
    setMembershow(!membershow);
  };

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown-container')) {
        setMembershow(false);
      }
      if (!event.target.closest('.dropdownref')) {
        setShow(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [membershow, show]);

  return (
    <>
      {displayPopup && <Login onClose={handleLoginClose} />}
      <Responsivenavbar />
      <div className='w-full hidden lg:block'>
        <div className='w-11/12 mx-auto'>
          <div className='flex items-center justify-between py-3'>
            <div className='w-36 h-auto'>
              <Link to='/'>
                <img src={Logo} alt='Logo' className='w-full h-full' />
              </Link>
            </div>
            <div className='flex lg:space-x-1 xl:space-x-3'>
              <div className='dropdownref'>
                <ul className='flex space-x-4 text-black text-base font-semibold cursor-pointer relative'>
                  <li className='main-color flex items-center space-x-1 py-2 px-3' onClick={handleShow}>
                    <span>Doctors</span>
                    <span><IoIosArrowDown /></span>
                  </li>
                  {show && (
                    <div className='absolute bg-white w-56 top-10 rounded text-black py-1 px-1 box-shadow' style={{ zIndex: 1 }}>
                      <p className='py-2 text-primary text-base font-semibold'>Find doctor by speciality</p>
                      <ul className='flex flex-col space-y-2 font-normal'>
                        {ServiesDvm.map((categories, index) => (
                          <li key={categories._id}>
                            <div className='main-color px-1 py-2 flex items-center justify-between' onClick={() => setListshow(index)}>
                              <span>{categories.name}</span> <span className='pr-4'><IoIosArrowDown /></span>
                            </div>
                            {listshow === index && (
                              <div className='text-[13px] text-[#686868]'>
                                {CitySelect.map((city) => (
                                  <div key={city._id} className='px-1 py-2' onClick={() => handleCategoryClick(city.name, categories._id)}>
                                    {categories.name} in {city.name}
                                  </div>
                                ))}
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <li className='main-color py-2 px-3'>Health Blog</li>
                  <Link to='/joinasdoctor'><li className='main-color py-2 px-3'>Join as Doctor</li></Link>
                  <li className='main-color py-2 px-3'>Help</li>
                </ul>
              </div>
              <div>
                <ul className='flex items-center space-x-4'>
                  <li className='relative dropdown-container'>
                    {selectedPatient ? (
                      <p className='flex items-center text-[--primary] text-base font-semibold cursor-pointer' onClick={handleUserMenu}>
                        <span>{selectedPatient}</span>
                        <span><IoIosArrowDown /></span>
                      </p>
                    ) : (
                      <button className='bg-[--primary] text-white w-[156px] h-[40px] text-base rounded cursor-pointer' onClick={handleLoginOTP}>Login / Signup</button>
                    )}
                    {membershow && (
                      <div className='w-[140px] left-[-30px] h-14 top-9 bg-white absolute border rounded-md cursor-pointer'>
                        <ul className='text-sm px-5 py-1 cursor-pointer'>
                          <li><Link to='/member/'>Member Area</Link></li>
                          <li onClick={logoutUser}>Log Out</li>
                        </ul>
                      </div>
                    )}
                  </li>
                  <li>
                    <button className='flex items-center justify-center text-white space-x-3 w-[156px] h-[40px] bg-[#28B446] text-base rounded'>
                      <span><FaPhoneAlt /></span>
                      <span>04238900939</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
