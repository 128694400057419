//  get all the doctor by city /doctor/getAllDoctor
import {setCityName,setLoading,setError} from '../reducers/cityreducer';
import axios from 'axios';
export const fetchapicityinfo=(city)=>async(dispatch)=>{
try{
    dispatch(setLoading(true));
    const response=await axios.get(`https://callvet.raabtastore.com/api/doctor/getAllDoctor?city=${city}`)
     dispatch(setCityName(response.data.data.docs))
}
catch(error){
    console.log('Error are showing',error);
    dispatch(setError(true)); 
}
finally{
    dispatch(setLoading(false))
}
}