import React,{useEffect} from 'react'
import Navbar from '../../components/global/navbar';
import Footer from '../../components/global/footer';
import Headroom from 'react-headroom';
import { useDispatch,useSelector } from 'react-redux';
import {fetchapicities} from '../../redux/actions/citylistaction'
import Loadingimage from '../../assests/Images/globalpics/loadingimg.svg'



const Doctorcities = () => {
    const dispatch=useDispatch();
    const {CitySelect,loading}=useSelector((state)=>state.city);
    useEffect(()=>{
          dispatch(fetchapicities())
    },[dispatch])
  return (
    <>
<Headroom className='z-100' >
      <div className='navshadow  bg-white block  '>
      <Navbar/>
      </div>
      </Headroom>
    {
        loading ?(
            <div className='w-full h-screen flex justify-center items-center bg-[#F8F9FA]'>
         <img src={Loadingimage} alt='img' className='w-16 h-16 '/>
      </div>
        ):(
            <div className='w-full h-screen bg-[#F8F9FA] '>
    <div className='w-11/12 mx-auto  text-lg font-semibold py-7 '>
         <h1>Explore doctors in cities across Pakistan</h1>
         <div className='flex flex-col sm:flex-row space-y-3  space-x-0  sm:space-x-3 sm:space-y-0 w-full my-2'>
         {CitySelect.map((city) => (
        <div className='w-full sm:w-1/3 border border-black rounded py-2 ' key={city._id}>
          <p className='text-sm font-normal text-opacity-75 px-2'>Doctors in {city.name}</p>
        </div>
      ))}
         </div>
    </div>
</div>    
        )
    }
<Footer/>
    </>
  )
}

export default Doctorcities
