import { createSlice } from "@reduxjs/toolkit";
const  LoginotpReducer = createSlice({
    name:'otplogin',
    initialState:{
       phone:'',   
       loading:false,
       error: null,
       success:false   
    },
    reducers:{
        setOtpLogin(state,action){
            state.phone=action.payload;
        },
        setLoading(state,action){
            state.loading=action.payload;
        },
        setError(state,action){
            state.error=action.payload
        },
        setSuccess(state,action){
            state.success = action.payload;
        }
    }
})
export const {setOtpLogin, setLoading, setError, setSuccess}=LoginotpReducer.actions;
export default LoginotpReducer.reducer;

