import { configureStore } from "@reduxjs/toolkit";
import ServicesReducer from '../reducers/servicesreducer';
import CityReducer from '../reducers/citylistreducer';
import Doctorlistreducer from "../reducers/doctorlistreducer";
import Viewprofilereducer from "../reducers/viewprofilereducer";
import CategorycityReducer from '../reducers/categorycityreducer';
import AppointmentReducer from '../reducers/bookappoinmentreducer';
import LoginotpaReducer from '../reducers/loginotpreducer';
import verfiyloginreducer from "../reducers/verfiyloginreducer";
import appointmentDetailReducer from '../reducers/appionmentdetailreducer';
import CityInfoReducer from "../reducers/cityreducer";
import PaycashReducer from '../reducers/paycashreducer'
const store=configureStore({
   reducer:{
       services:ServicesReducer,
       city:CityReducer,
       doctor:Doctorlistreducer,
       view:Viewprofilereducer,
       citycategory:CategorycityReducer,
       appointment:AppointmentReducer,
       otplogin:LoginotpaReducer,
       userverfied:verfiyloginreducer,
       userinfo:appointmentDetailReducer,
       cityname:CityInfoReducer,
       paycash:PaycashReducer


   },
})
export default store;