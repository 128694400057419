import React from 'react'
import { CiUser } from "react-icons/ci";
import { CiCalendar } from "react-icons/ci";
import { MdOutlinePayment } from "react-icons/md";
import Image from '../../../../../assests/Images/doctorlistsvg/image 120.svg';
import DoctorImage from '../../../../../assests/Images/jpeg/default.png'
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoLocation } from "react-icons/io5";





// NameofConfirm,dayofConfirm,monthofConfirm,timeofConfirm,doctorimage,doctorname,doctorhospital,address,bio,fee


const Pateintdetail = ({name,fee,address,time,doctor}) => {

  return (
       <>
        <div className='w-full box rounded-md  py-7 mt-7 bg-white  '>
          <div className='flex flex-col space-y-7 '>
          <div className=' flex flex-row items-center justify-between border-b px-4 py-2 '>
            <div className='flex flex-row items-center space-x-3'>
              <img src={DoctorImage} alt='img' className='w-[90px] h-[90px] rounded-full'/>
            <div>
<h2 className='text-lg leading-9'>{doctor}</h2>
          </div>
          </div>
          <div className='text-xl cursor-pointer'>
            <HiOutlineDotsVertical/>
          </div>
          </div>
          
           <div className='flex flex-row justify-between  w-4/5'>
           <div className='flex flex-row px-3 space-x-3'>
          <div className='text-3xl text-[#686868] text-opacity-60'>
          <CiUser/>
          </div>
          <div className='text-black'>
        <p  className='text-sm text-opacity-60 leading-8'> Patient Name</p> 
        <h1 className='text-base font-normal'>{name}</h1>
          </div>

          </div>


          <div className='flex flex-row px-3 space-x-3'>
          <div className='text-3xl text-[#686868] text-opacity-60 '>
          <MdOutlinePayment/>
          </div>
          <div className='text-black'>
        <p  className='text-sm text-opacity-60 leading-8'> Fee</p> 
       <h1 className='text-base font-normal leading-8'>{fee}</h1>
          </div>

          </div>
        

           </div>
        

          <div className='flex flex-row px-3 space-x-3 w-4/5'>
          <div className='text-3xl text-[#686868] text-opacity-60'>
          <CiCalendar/>
          </div>
          <div className='text-black'>
        <p  className='text-sm text-opacity-60 leading-8'> Appointment Time</p> 
          <h1 className='text-base font-normal'>{time}</h1>
          </div>

          </div>

          <div className='flex flex-row px-3 space-x-3 w-4/5'>
          <div className='text-3xl text-[#46484b] text-opacity-60'>
          <IoLocation/>
          </div>
          <div className='text-black'>
        <p  className='text-sm text-opacity-60 leading-8'>location</p> 
       <h1 className='text-base font-normal'>{address}</h1>
          </div>
          </div>      
          </div>
        </div>
       </>
  )
}
export default Pateintdetail
