import React, { useState, useContext, useEffect  } from 'react'
import AppoinetmentFor from './left'
import Confrimbooking from './right'
import { useLocation,useNavigate } from 'react-router-dom'
import { PatientContext } from '../../../context/pateintcontext'
import Addpateintpop from './addpateintpopup'
import {useDispatch,useSelector } from 'react-redux'
import {createAppointment} from  '../../../redux/actions/appionmentdetailaction'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
const Mainappoinetmentdetail = () => {
   const dispatch=useDispatch();
   const storedAuthData = localStorage.getItem('authData');
   const authData = storedAuthData ? JSON.parse(storedAuthData) : null;
   const initialPatientName = authData && authData.user && authData.user.username ? authData.user.username : '';

   const {appointment, error, message} = useSelector((state) => state.userinfo);
   const {setSelectedPatient } = useContext(PatientContext);
  const [showpopup,setShowpopup]=useState(false);
  const [Btnsubmittion,setBtnsubmittion]=useState(false);
  const [pateinname,setPateintName]=useState(initialPatientName)
  const [addpateintname,setAddpateintname]=useState();
  const [pateinterror ,setPateinterror]=useState(false);
  const [patnameerror ,setPatnameerror]=useState(false);
  const[selectedPeterror,setSelectedPeterror]=useState(false);
  const [appointmenterror,setAppointmenterror]=useState(false);
  const [paymenterror,setPaymenterror]=useState(false)
   const [petname,setPetname]=useState('')
   const [selectedPet, setSelectedPet] = useState("");
   const [payment,setPayment]=useState('');
   const navigate =useNavigate();
  const location =useLocation();  
  const pateintnumber=location.state ? location.state.PatientNumber:'';
  const dayofbooking=location.state ? location.state.day:'';
  const monthofbooking=location.state ? location.state.mon :'';
  const timeofbooking=location.state ? location.state.time :'';
  const doctorname=location.state ? location.state.doctorname:'';
  const doctorpicture=location.state ? location.state.doctorimage:'';
  const doctorfee=location.state ? location.state.fee:'';
  const hospitalname=location.state ? location.state.hospitalname:'';
  const homecheckup=location.state ? location.state.homecheckup:'';
  const dateappionment=location.state ? location.state.bookingappionment:'';
  const hospitalid=location.state ? location.state.hospitalid:'';
  const  doctorId=location.state ? location.state.doctorId:'';
  useEffect(() => {
    if (appointment) {
      if (message) {
        toast.success(message, {
          position: 'top-center',
        });
      }
      if (payment === 0) {
        navigateToPaymentOnline();
      } else if (payment === 1) {
        navigateToPaymentCash(appointment._id);
      } else if (paymenterror) {
        toast.error(paymenterror, {
          position: 'top-center',
        });
      }
    }
  }, [appointment, message, error, payment, paymenterror, navigate]);
  const navigateToPaymentOnline = () => {
    navigate(`/payonline/`, {
      state: {
        months: monthofbooking,
        days: dayofbooking,
        times: timeofbooking,
        picturedoctor: doctorpicture,
        nameofdoctor: doctorname,
        nameofhospital: hospitalname,
        feeofdoctor: doctorfee,
      },
    });
  };
  const navigateToPaymentCash = (appointmentId) => {
    navigate(`/paycash/${appointmentId}`, {replace:true});
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    let isValid = true;
    setBtnsubmittion(true);
    setTimeout(() => {
      setBtnsubmittion(false);
    }, 20000);
    if (!pateinname.trim()) {
      setPateinterror('Please enter the Name');
      isValid = false;
    } else {
      setPateinterror('');
    }
    if (!petname.trim()) {
      setPatnameerror('Please enter the pet name');
      isValid = false;
    } else {
      setPatnameerror('');
    }

    if (!selectedPet.trim()) {
      setSelectedPeterror('Please enter the pet type');
      isValid = false;
    } else {
      setSelectedPeterror('');
    }

    if (!payment) {
      setPaymenterror('Please select a Payment method');
      isValid = false;
    } else {
      setPaymenterror('');
    }

    if (isValid) {
      setSelectedPatient(pateinname);

      const appointmentData = {
        date: dateappionment,
        doctor: doctorId,
        hospital: hospitalid,
        time: timeofbooking,
        petId: '',
        petOwnerName: pateinname,
        phoneNumber: pateintnumber,
        petType: selectedPet,
        petName: petname,
        payBy: payment,
        appointmentType: '',
        address: '',
        lat: '',
        lng: '',
      };

      console.log('Appointment Data:', appointmentData);

      dispatch(createAppointment(appointmentData)).then(response => {
        if (response.success) {
          if (payment === 0) {
            navigateToPaymentOnline();
          } else if (payment === 1) {
            navigateToPaymentCash(response.appointmentId);
          }
        }
      });
    }
  }

  return (
     <>
     {
      showpopup &&
      <Addpateintpop showpopup={showpopup} setShowpopup={setShowpopup} phonenumber={pateintnumber}  setAddpateintname={setAddpateintname} />
     }
        <div className='w-full pb-0 lg:pb-10 mt-3'>
          <div >
          <form onSubmit={handlesubmit}  
          className='flex  flex-col space-y-3 lg:space-y-0 lg:flex-row w-full lg:w-11/12 mx-auto  md:my-7 space-x-0 lg:space-x-3 '>
           <div className='w-full  sm:w-11/12  md:w-full mx-auto md:mx-0 lg:w-[70%] '>
            <AppoinetmentFor phonenumber={pateintnumber}  setShowpopup={setShowpopup} pateinname={pateinname} setPateintName={setPateintName}  setPayment={setPayment}
              addpateintname={addpateintname} pateinterror={pateinterror} appointmenterror={appointmenterror}
              paymenterror={paymenterror}  petname={petname}  setPetname={setPetname} patnameerror={patnameerror} selectedPet={selectedPet} setSelectedPet={setSelectedPet}  selectedPeterror={selectedPeterror}
              doctorfee={doctorfee}    homecheckup={homecheckup}  setPaymenterror={setPaymenterror} payment={payment}
            />
           </div>  
           <div className='w-full sticky bottom-0 sm:static  mx-auto lg:mx-0  z-50   lg:w-[30%]  '>
            <Confrimbooking day={dayofbooking} month={monthofbooking} time={timeofbooking} doctorname={doctorname} doctorpicture={doctorpicture}  doctorfee={doctorfee} hospitalname={hospitalname}  homecheckup={homecheckup} Btnsubmittion={Btnsubmittion} />
           </div>  
           </form>
          </div>
        </div>
        <ToastContainer />

     </>
  )
}
export default Mainappoinetmentdetail;
