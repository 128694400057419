import React from 'react'
import cityimage from '../../../assests/Images/jpeg/1630500399_default.jpeg';
const Findcity = ({cityname}) => {
  console.log(cityname);
  return (
    <div className='w-full  relative' style={{backgroundImage:`url(${cityimage})`,backgroundSize:'100% 100%',height:'300px'}}>
      <div className='w-full h-full   bg-gradient-to-r from-[#242463] to-bg-transparent	' >
</div>  
    <div  className='absolute bottom-12 left-6'>
   <h1 className='text-white text-[22px] font-semibold'>Find and book best doctors in {cityname}</h1>
     <p className='text-white w-5/6 text-[15px]'>{cityname}  city of Pakistan, located in the province of Punjab. It is an ancient city with a history dating back almost 5000 years. {cityname} is known for its various shrines and mausoleums </p>
    </div>
    </div>
  )
}

export default Findcity
