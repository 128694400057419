import React,{useState,useEffect} from 'react'
import { IoLocationOutline } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import {fetchapicities} from '../../../redux/actions/citylistaction';
import {fetchapiservices} from '../../../redux/actions/servicesaction'
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RxCross2 } from "react-icons/rx";
import { TbCurrentLocation } from "react-icons/tb";
import Loadingimage from '../../../assests/Images/globalpics/loadingimg.svg';
import notfound from '../../../assests/Images/globalpics/not-found.png'
import {fetchapidoctors} from '../../../redux/actions/doctorlistaction'
const Selectoption = ({handleclose}) => {

    const [showcity,setShowcity]=useState(false);
    const [selectedcity,setSelectedcity]=useState('Lahore');
    const [selectedcategory,setSelectedcategory]=useState('');
    const [clearcategory,setClearcategory]=useState(false);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [displayAllCities, setDisplayAllCities] = useState(true);
    const {CitySelect}=useSelector((state)=>state.city);
    const {ServiesDvm,loading}=useSelector((state)=>state.services);
    const {viewdoctor}=useSelector((state)=>state.doctor);
     const handleCityChange=(e)=>{
            setSelectedcity(e.target.value);
            setDisplayAllCities(false);
     }


     const filteredCities = displayAllCities
     ? CitySelect
     : CitySelect.filter(city => city.name.toLowerCase().includes(selectedcity.toLowerCase()));



     const handleCategoryChange=(e)=>{
        setSelectedcategory(e.target.value);
        setClearcategory(true)
        setSearchPerformed(true);
    }
     const emptycategory=()=>{
        setSelectedcategory('');
    }

     const filterdCategories=ServiesDvm.filter(category=>category.name.toLowerCase().includes(selectedcategory.toLowerCase()));
     const filterdoctorname=viewdoctor.filter(doctorname=>doctorname.username.toLowerCase().includes(selectedcategory.toLowerCase()))

    const dispatch=useDispatch();
    const navigate = useNavigate();
    useEffect(()=>{
    dispatch(fetchapicities());
     },[dispatch])
    useEffect(()=>{
        dispatch(fetchapiservices());
    },[dispatch])
    
    useEffect(()=>{
        dispatch(fetchapidoctors());
    },[dispatch])
const handleSelect=(city)=>{
    setSelectedcity(city)
    setShowcity(false); 
}
const handleSelectcategory=(category)=>{
    const city=selectedcity || 'Lahore';
    setSelectedcategory(category);
    setSelectedcity(city);
    setShowcity(false); 
    navigate(`/doctors/${city}/${category}`)
}
const handleSelectDoctor=(doctorId)=>{
    const doctorname=selectedcategory;
    setSelectedcategory(doctorname);
    setShowcity(false); 
   navigate(`/doctor/${doctorId}`)

    
}
const handleDisplaylist = (index) => {
    if (index === 1) {
        setShowcity(index); 
        setDisplayAllCities(true);
    }
    if (index === 2) {
        setShowcity(index); 
    } 
};


  return (
    <>
        <div className='w-full h-screen  fixed top-0 left-0 z-40  '  style={{backgroundColor:'rgba(0, 0, 0, 0.2)'}}>
            <div className='flex flex-col items-center    '>
            <form className='w-full flex flex-col items-center  '   >
                <div className='w-full  md:w-2/4 bg-white h-auto  xl:mt-4  py-6 xl:py-2   rounded-sm relative '>
                       <h1 className='text-center pt-2 xl:pt-5 text-[--primary] text-xl'>Search for Doctors</h1>
                       <RxCross2 className='absolute top-2 xl:top-10 right-7 text-lg cursor-pointer' onClick={handleclose}/>
                     <div  className='w-11/12 flex flex-col space-y-2 mx-auto mt-2 '>
                     <div className='relative w-full  '>
                     <input type='text' placeholder='Enter Your City' value={selectedcity} onChange={(e)=>handleCityChange(e)} className='border  w-full outline-none py-3 px-7 rounded-sm text-[15px]' onClick={()=>handleDisplaylist(1)}    />
                         <IoLocationOutline className=' absolute top-4 left-2 text-lg text-[--primary]  '/>
                         <div className='w-[20%] h-[90%] absolute right-[3px] top-[2px]   bg-[#EDEFF9] flex flex-row space-x-1  items-center justify-center cursor-pointer rounded '>
                         <div className='text-[--primary]'>
                         <TbCurrentLocation/>
                         </div>
                         <div className='text-[--primary] text-sm'>
                              Detect
                         </div>
                         </div>  
                             
                     </div>
                    
                     <div className='relative w-full'>
                     <input type='text' value={selectedcategory} onChange={(e)=>handleCategoryChange(e)} placeholder='Search for doctors,services'  className='border  w-full outline-none py-3 px-7 rounded-sm text-[15px]' onClick={()=>handleDisplaylist(2)}   />
                     <CiSearch className=' absolute top-4 left-2 text-lg text-[--primary]'/>
                     <div className='absolute top-4 right-1 flex items-center space-x-2'>
                     {clearcategory ?  <RxCross2  className=' cursor-pointer' onClick={()=>emptycategory()}/> : ''}
                     {/* {loading   && <div> <img src={Loadingimage} alt='img' className='h-5 w-5'/> </div>  } */}
                     </div>

                     </div>
                    
                     </div>

                </div>


                
                {showcity===1 &&(
                    <div className='w-full md:w-2/4  bg-white h-screen xl:h-auto mt-0  xl:mt-4   rounded-sm z-50'>
                         <ul className='cursor-pointer'>

                            {
                                filteredCities.length >0 ?(
                                    filteredCities.map((cities)=>(
                              <li key={cities._id}  className='flex space-x-2 items-center text-base text-black text-opacity-85 border-b-2 py-3 hover:bg-[#F3F2F7]' onClick={()=>handleSelect(cities.name)}>
                                  <span className='text-[--primary] px-3'><CiSearch/></span>  <span>{cities.name}</span>
                              </li>
                            )
                            )
                                ):(
                                    <li className='flex justify-center items-center h-72 bg-[#F8F9FA] '>
                                        <p className='text-[--primary] text-base '>No Location Found</p>
                                    </li>
                                )
                                
                                }
                         </ul>
                    </div>
                     )
                     } 
                     {showcity===2 &&(
                    <div className='w-full md:w-2/4  bg-white h-screen xl:h-auto mt-0    xl:mt-4  rounded-sm overflow-hidden  overflow-y-auto '>
                    <ul className='cursor-pointer'>
            {(filterdCategories.length > 0 || searchPerformed && filterdoctorname.length > 0) ? (
              <>
                {filterdCategories.map((category) => (
                  <li
                    key={category._id}
                    className='flex space-x-2 items-center text-base text-black text-opacity-85 border-b-2 py-3 hover:bg-[#F3F2F7]'
                    onClick={() => handleSelectcategory(category._id)}
                  >
                    <span className='text-[--primary] px-3'><CiSearch /></span>
                    <span>{category.name}</span>
                  </li>
                ))}

                {
                    
                    searchPerformed && filterdoctorname.map((doctor) => (
                    <>
                  
                  <li
                    key={doctor._id}
                    className='flex space-x-2 items-center text-base text-black text-opacity-85 border-b-2 py-3 hover:bg-[#F3F2F7]'
                    onClick={()=>handleSelectDoctor(doctor._id)}
                    >
                    <div className='px-1'><img src={`https://callvet.raabtastore.com/${doctor.image}`} alt='Doctor' className='w-9 h-9 rounded-full' /></div>
                    <div>{doctor.username}</div>
                  </li>
                      
                    </>
                  
                ))}
              </>
            ) : (
              <li className='flex flex-col space-y-2 justify-center items-center text-base text-black text-opacity-85 bg-[#F8F9FA] h-72'>
                <div className='w-20 h-20'>
                  <img src={notfound} alt='Not found' />
                </div>
                <div className='text-[--primary]'>No match found</div>
              </li>
            )}
          </ul> 
                    </div>
                     )
                     }

                   

                </form>
                    
              



                
            </div>
        </div>
    </>
  )
}

export default Selectoption
