import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RiArrowRightSLine } from 'react-icons/ri';
import LoadingImage from '../../../assests/Images/globalpics/loadingimg.svg';
import { fetchapicities } from '../../../redux/actions/citylistaction';
import { fetchapiservices } from '../../../redux/actions/servicesaction';

const Finddoctorcity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { CitySelect, loading } = useSelector((state) => state.city);
  // category api
  const { ServiesDvm } = useSelector((state) => state.services);

  useEffect(() => {
    dispatch(fetchapicities());
    dispatch(fetchapiservices());
  }, [dispatch]);

  const handleToShow = (city, category) => {
    navigate(`/doctors/${city}/${category}`);
  };

  return (
    <div className='w-full pb-20'>
      <div className='w-11/12 py-3 mx-auto'>
        <div className='text-start my-7 text-2xl sm:text-xl font-semibold'>
          We're Available in These Cities
        </div>
        {loading ? (
          <div className='w-full h-screen flex justify-center items-center'>
            <img src={LoadingImage} alt='Loading' className='w-16 h-16' />
          </div>
        ) : (
          <div className='grid grid-cols-2 md:grid-cols-3 gap-4 py-2 cursor-pointer text-base md:text-lg font-semibold'>
            {CitySelect.map((city) => (
              <div key={city._id} className='py-3'>
                {city.name}
                {ServiesDvm.map((category) => (
                  <div
                    key={category._id}
                    onClick={() => handleToShow(city.name, category._id)}
                    className='text-sm font-normal py-2 flex items-center hover:underline cursor-pointer'
                  >
                    <span className='text-[--primary]'>
                      <RiArrowRightSLine />
                    </span>
                    <span>
                      Best {category.name} in {city.name}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Finddoctorcity;

