import React from 'react'
import { FaArrowLeft } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';

const Selectbooknav = () => {
  const navigate = useNavigate();
  
  const handleGoBack=()=>{
    navigate(-1);

  }

  return (
       <>
        <div className='bg-white w-full border-b-[1px]'>
          <div className='w-11/12 mx-auto flex flex-row items-center justify-between py-2'>
            <div className='flex flex-row items-center space-x-2 text-[15px]'>
              <Link onClick={handleGoBack}><FaArrowLeft/></Link>  
              <p className='font-semibold'>Select Date && Time </p>
            </div>
          {/* <div className='text-[15px] text-[--primary]'>
        <FaPhoneAlt/>
          </div> */}
          </div>
        </div>
       </>
  )
}

export default Selectbooknav;
