import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const authData = JSON.parse(localStorage.getItem('authData'));
  const isAuthenticated = authData && authData.token && authData.user;
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" />;
};

export default ProtectedRoute;
