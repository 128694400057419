import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// Pages
import Home from './pages/home';
import Doctor from './pages/doctor';
import Appoinetment from './pages/appoinetment';
import Appoinetmentdetail from './pages/appoinementdetail';
import City from './pages/city';
import Member from './pages/member';
import JoinDoctor from './pages/joinasdoctor';
import DoctorCities from './pages/doctorcities/index';
// Components
import ViewProfile from './components/userdetail';
import PayOnline from './components/pages/pays/payonline';
import PayCash from './components/pages/pays/paycash';
// Utils
import ProtectedRoute from './Protectedroute';
import Error from './error';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} errorElement={<Error />} />
        <Route path="/doctors/:city/:category" element={<Doctor/>} errorElement={<Error />} />
        <Route path="/doctor/:id" element={<ViewProfile/>} errorElement={<Error />} />
        <Route path='/appoinetment/:doctorid/:hospitalAvailableid' element={<Appoinetment/>} errorElement={<Error/>}/>       
         <Route path="/appointmentdetail" element={<ProtectedRoute element={Appoinetmentdetail} />} />
        <Route path="/payonline/" element={<PayOnline />} errorElement={<Error />} />
        <Route path="/paycash/:appointmentId" element={<PayCash />} errorElement={<Error />} />
        <Route path="/member/" element={<Member />} errorElement={<Error />} />
        <Route path="/joinasdoctor" element={<JoinDoctor />} errorElement={<Error />} />
        <Route path="/pakistan/:city" element={<City />} errorElement={<Error />} />
        <Route path="/cities" element={<DoctorCities />} errorElement={<Error />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
};
export default App;
