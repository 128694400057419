import React,{useState} from 'react'
import { RxCross2 } from "react-icons/rx";
import './addpateint.css'
import { IoIosInformationCircleOutline } from "react-icons/io";
const  Addpateintpop = ({ phonenumber, showpopup, setShowpopup,setAddpateintname }) => {
    console.log(phonenumber);
    const[active,setActive]=useState(1);
    const handleclickradio=(index)=>{
        setActive(index);
    }
const handleclosebtn=()=>{
    setShowpopup(!showpopup)
}
const handlepopup=(e)=>{
   e.preventDefault();
   setShowpopup(false)
}
const handleaddpateint=(e)=>{
  setAddpateintname(e.target.value); 
}
  return (
     <>
        <div className='w-full h-screen fixed top-0 left-0'  >
               <form onSubmit={handlepopup} className='bg-white w-1/3 h-[400px] mx-auto my-6 rounded-md box px-3 py-2'>
                  <div className='flex flex-col space-y-3'>
                <div className='flex flex-row items-center justify-between'>
                <p className='text-lg'>Add a Family Member</p>
                <div onClick={handleclosebtn} className='cursor-pointer'><RxCross2/></div>
                </div>
                <div className='flex items-center w-full space-x-2 cursor-pointer'>
                <div className='flex flex-col space-y-2 w-3/5'>
                 <label className='text-sm'>Patient’s Name</label>
                <input type='text' className='outline-none border rounded-md text-sm px-3 py-2' onChange={handleaddpateint}/>
                </div>
                <div className='flex flex-col space-y-2  w-2/5'>
                 <label className='text-sm '>Relation</label>
          <select className='outline-none border rounded-md text-[13px] text-[#46484B]  py-2  '>
                  <option value=''>Relation</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                </div>


                <div className=' flex flex-col space-y-2  '>
        <legend className='text-[15px]'>Choose your gender:</legend>
        <div className='flex flex-row space-x-3  px-1 '>
        <div className={`flex space-x-3 cursor-pointer px-4 py-2 rounded-md ${active ===1 ? 'bg-[#F4F7FF] text-[#46484b] text-opacity-100  font-semibold ':' text-[#46484b] text-opacity-60'} `} onClick={()=>handleclickradio(1)}>
        <input type="radio" name="gender" id="male" value="male" className='radiobox'  checked/>
        <label for="male" className='cursor-pointer text-[#46484b]'>Male</label>
        </div>
        <div className={`flex space-x-3 cursor-pointer px-4 py-2 rounded-md ${active ===2 ? 'bg-[#F4F7FF]  text-[#46484b] text-opacity-100 font-semibold ':' text-[#46484b] text-opacity-60'} `} onClick={()=>handleclickradio(2)}>
        <input type="radio" name="gender" id="female" value="female" className='radiobox'/>
        <label for="female" className='cursor-pointer text-[#46484b]'>Female</label>
        </div>
        </div>

       
        
        
        </div>

        <div className='flex flex-col space-y-2  '>
               <label className='text-[#46484b]'>Phone Number</label>
              <input type='text' value={phonenumber}  className='w-full border outline-none py-2 px-3 text-black rounded-md bg-[#F4F7FF]' readOnly/>
              <p className='flex items-center text-xs space-x-1 text-[#46484b] text-opacity-70'><span><IoIosInformationCircleOutline/></span><span>You will be contacted through this number</span></p>
           </div>
              
              <div>
              <button className='w-3/6 text-sm bg-[#E6220C] py-2 text-white rounded-md my-4'>
                    Add Family Member
                </button>
              </div>
                
              

                  </div>
               </form>
        </div>
     </>
  )
}

export default  Addpateintpop
