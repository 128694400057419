import React,{useEffect} from 'react'
import { FaLongArrowAltRight } from "react-icons/fa";
import Direction from '../../../assests/Images/homepics/locted.svg'
import Mobile from '../../../assests/Images/homepics/mobilecallvet.svg'
import Scan from '../../../assests/Images/homepics/scan.svg'
import Googlestore from '../../../assests/Images/homepics/googleplay.svg'
import Applestore from '../../../assests/Images/homepics/appstore.svg'

const Providers = () => {
  
  return (
      <>
        <div className='w-full  py-10'  >
          <div className='w-11/12 flex flex-col   sm:flex-row sm:flex-wrap lg:flex-nowrap    mx-auto  '>
             <div className='flex flex-col space-y-5 w-full sm:w-2/4  lg:w-2/5 relative p-5 '>
               <h2 className='text-2xl sm:text-3xl  xl:text-4xl font-bold leading-7 sm:leading-9 xl:leading-[45px] sm:pt-8'>Eat Healthy, Stay Healthy</h2>
               <p className='text-[15px] text-[#686868]'>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32.</p>
               <button  className='w-full h-10  md:w-[206px] md:h-11 xl:h-[50px] bg-[#4C63BD] text-white flex items-center justify-center rounded space-x-2 box-shadow '>
               <span>Read More</span>   
                <span><FaLongArrowAltRight/></span>
               </button>
               <div className='hidden lg:block'>
               <img src={Direction} alt='img' className='lg:w-32 xl:w-52 absolute lg:top-96 xl:top-72 lg:right-[-20px]'/>
               </div>
             </div>
             <div className='flex flex-col w-full sm:w-2/4 lg:w-[30%] items-center '>
             <img src={Mobile} alt='img' className='w-4/5 h-[400px] lg:h-[500px] lg:mt-6'/>
             </div>
             <div className='flex flex-col w-full sm:w-2/4 sm:mx-auto lg:w-2/5 '>
             <p className='text-center text-xl font-semibold pt-8 lg:pt-20'>Scan the QR code to get the app now</p>
             <img src={Scan}  alt='img' className='mx-auto my-4 w-40 h-40'/>
             <div className='flex flex-row w-full px-3 mt-1 lg:mt-40 xl:mt-32 space-x-3'>
              <img src={Googlestore} alt='img' className='w-2/4 h-14'/>
              <img src={Applestore} alt='img' className='w-2/4 h-14'/>
             </div>
             </div>
          </div>
        </div>
      </>
  )
}

export default Providers
