// book an appionment api https://callvet.raabtastore.com/api/appointment
import axios from 'axios';
import { setAppointment, setError, setLoading,setMessage} from '../reducers/appionmentdetailreducer'; 
import { toast } from 'react-toastify';
const API_ENDPOINT = 'https://callvet.raabtastore.com/api/appointment'; 
export const createAppointment = (appointmentData) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const authData = JSON.parse(localStorage.getItem('authData'));
    const token = authData ? authData.token : null; 
    if (!token) {
      throw new Error('Authorization token not found');
    }
      const response = await axios.post(API_ENDPOINT, appointmentData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const appointmentId = response.data.data.appointment._id; 
    dispatch(setMessage(response.data.data.message)); 
    dispatch(setAppointment(response.data.data));
    return { success: true, data: response.data.data,appointmentId };
  } catch (error) {
    dispatch(setError(error.message));
    toast.error(error.message); 
    return { success: false, error: error.message };
  } finally {
    dispatch(setLoading(false)); 
  }
};
