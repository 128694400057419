import React from 'react'
import Image from '../../../../assests/Images/Svg/Book Appointment with Dr. Samina Kausar at Iqraa Medical Complex (Ext.) in Johar Town, Lahore _ oladoc.com_files/1560423926_samina-kausar.webp'
import { FaRegClock } from "react-icons/fa";
import './submitappoinmet.css'


const  Confrimbooking = ({day,month,time,doctorname,doctorpicture,hospitalname,doctorfee,homecheckup,Btnsubmittion }) => {
  return (
      <>
      <div className='w-full rounded  box py-4 px-4 bg-white ' >
      <div className='flex flex-row space-x-2'>
      <div className='w-[20%] hidden md:block '>
    <img src={`https://callvet.raabtastore.com/${doctorpicture}`} alt='img' className='w-14 h-14 rounded-full' />
      </div>
      <div className='flex flex-col space-y-3 w-[80%] '>
      <div className='block md:hidden'>
      <div  className=' flex flex-row text-lg items-center space-x-2 '>
     <img src={`https://callvet.raabtastore.com/${doctorpicture}`} alt='img' className='w-14 h-14 rounded-full' />
     <div>{doctorname}</div>
     </div>
     <div className='flex flex-row space-x-2'>
     <div>
     <p className='text-sm text-[#46484B] leading-5 '>{hospitalname} <b>{doctorfee}</b></p>
     </div>
     <div>
     <p className='text-black text-sm'>{homecheckup ? '(Home CheckUp)' :'(Clinic Checkup)'}</p>

     </div>
     </div>
      </div>
      <h2 className='text-lg hidden md:block'>{doctorname}</h2>
     <p className='text-sm text-[#46484B] leading-4 hidden md:block   '>
     {hospitalname} <b>{doctorfee}</b> <span><p className='text-black'>{homecheckup ? '(Home CheckUp)' :'(Clinic Checkup)'}</p>
</span></p>
<p className='flex items-center justify-center py-1 rounded-lg text-[#46484B]   space-x-1 text-[15px] bg-[#F4F7FF] w-3/5 '>
<span><FaRegClock/></span> <span>{month}</span> <span> {day}</span><span>{time}</span>
</p>
      </div>
      </div>

      <div className='my-3'>
      <button type='submit' className='rounded py-2 text-center text-base bg-[--primary] text-white w-full cursor-pointer flex justify-center'>
       { Btnsubmittion? (
          <div className='loader'>
</div>
       ):(
         <>
         Confrim booking
         </>
       )
       } 
      </button>
      </div>

      </div>
      </>
  )
}

export default  Confrimbooking
