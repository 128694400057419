import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { FaLaptop } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaCalendarCheck } from "react-icons/fa6";
import { IoDownloadSharp } from "react-icons/io5";
import { FaCreditCard } from "react-icons/fa6";
import { TbStarsFilled } from "react-icons/tb";
const Doctorslider = () => {
  const responsive = {
    mobile: {
      breakpoint: { max: 767, min: 500 },
      items: 3,
      slidesToSlide: 1
    },
    smallscreen: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
      slidesToSlide: 1
    }
  };
 
  const items = [
    { icon: <FaLocationDot />, text: 'Doctors Near Me' },
    { icon: <FaCalendarCheck />, text: 'Available Today' },
    { icon: <IoDownloadSharp />, text: 'Doctors Below Rs.1000' },
    { icon: <FaCreditCard />, text: 'Lowest Fee' },
    { icon: <FaLaptop />, text: 'Online Now' },
    { icon: <TbStarsFilled />, text: 'Highest Rated' },
  ];
  return (
    <>
      <div className='w-full  ' >
    
        <div className='w-11/12 mx-auto  '>
        <div className='block md:hidden'>
        <Carousel
            axis='horizontal'
            showStatus={false}
            responsive={responsive}
            removeArrowOnDeviceType={["desktop,mobile,smallscreen"]}
            arrows={false}
            className='  w-full h-16 sm:h-20 md:h-14  lg:h-16 cursor-pointer px-3 sm:px-2    ' >
            {items.map((item, index) => (
          <div
            key={index}
            className="text-black flex mx-1 items-center font-medium border border-[--primary] h-10 rounded text-[11px] sm:text-xs"
          >
            <span className="bg-[--primary] w-1/4 h-full flex items-center justify-center text-white text-xl">
              {item.icon}
            </span>
            <span className="px-2">{item.text}</span>
          </div>
        ))}
   
          </Carousel>
        </div>
    <div className='hidden md:block'>
    <div className='grid  md:grid-cols-3 lg:grid-cols-6 gap-2 2xl:gap-3 '>
            <div className='text-black flex   items-center  font-medium border border-[--primary]   h-10     rounded text-[11px] sm:text-xs    '>
              <span className='bg-[--primary] w-1/4 h-full  flex items-center justify-center text-white text-xl'><FaLocationDot />
              </span>
              <span className='px-2'>Doctors  Near Me</span>
            </div>

            <div className='text-black flex   items-center  font-medium border border-[--primary]   h-10     rounded text-[11px] sm:text-xs    '>
              <span className='bg-[--primary] w-1/4 h-full  flex items-center justify-center text-white text-xl'>
                <FaCalendarCheck />
              </span>
              <span className='px-2'>Available Today</span>
            </div>

            <div className='text-black flex   items-center  font-medium border border-[--primary]   h-10     rounded text-[11px] sm:text-xs    '>
              <span className='bg-[--primary] w-1/4 h-full  flex items-center justify-center text-white text-xl'>
                <IoDownloadSharp />
              </span>
              <span className='px-2'>Doctors Blew Rs.1000</span>
            </div>

            <div className='text-black flex   items-center  font-medium border border-[--primary]   h-10     rounded text-[11px] sm:text-xs    '>
              <span className='bg-[--primary] w-1/4 h-full  flex items-center justify-center text-white text-xl'>
                <FaCreditCard />
              </span>
              <span className='px-2'>Lowest Fee</span>
            </div>

            <div className='text-black flex   items-center  font-medium border border-[--primary]   h-10     rounded text-[11px] sm:text-xs    '>
              <span className='bg-[--primary] w-1/4 h-full  flex items-center justify-center text-white text-xl'>
                <FaLaptop />
              </span>
              <span className='px-2'>Online Now</span>
            </div>


            <div className='text-black flex   items-center  font-medium border border-[--primary]   h-10     rounded text-[11px] sm:text-xs    '>
              <span className='bg-[--primary] w-1/4 h-full  flex items-center justify-center text-white text-xl'>
                <TbStarsFilled />
              </span>
              <span className='px-2'>Highest Rated</span>
            </div>


          </div>
    </div>
         
      

        </div>
      </div>
    </>
  )
}

export default Doctorslider
