import React, { useState } from 'react'
import Navbar from '../../components/global/navbar'
import Mainappoinetmentdetail from '../../components/pages/appoinetmentdetail/main'
import { FaArrowLeft } from "react-icons/fa";
import Headroom from 'react-headroom';
import { useNavigate } from 'react-router-dom';
const Appoinetmentdetail = () => {
const navigate=useNavigate();
  const handleGoBack=()=>{
    navigate(-1);
  }
  return (
       <>
       <div className='bg-[#F8F9FA]'>
       <div className='w-full bg-white border-b-[1px]  block md:hidden py-3'>
        <div className='w-11/12 mx-auto'>
        <div className='flex flex-row items-center space-x-2 text-[15px] mx-auto'>
            <div className='text-black font-semibold' onClick={handleGoBack}><FaArrowLeft/></div>
            <p className='font-semibold text-black'>Confrim booking</p>
          </div>
        </div>
        </div>
        <Headroom>
        <div className='navshadow bg-white hidden md:block z-50'>
        <Navbar/>
        </div>
        </Headroom>
       <Mainappoinetmentdetail  />
       </div>
       </>
  )
}
export default Appoinetmentdetail
