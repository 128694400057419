import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchapiservices } from '../../../redux/actions/servicesaction';
import LoadingImage from '../../../assests/Images/globalpics/loadingimg.svg';
import { useNavigate } from 'react-router-dom';

const Lookingfor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ServiesDvm, loading } = useSelector((state) => state.services);
  const { CitySelect } = useSelector((state) => state.city);
  const city = CitySelect[1]?.name || '';

  useEffect(() => {
    dispatch(fetchapiservices());
  }, [dispatch]);

  const handleCategory = (city, category) => {
    navigate(`/doctors/${city}/${category}`);
  };

  return (
    <div className='w-full'>
      <div className='w-11/12 mx-auto py-5'>
        <div className='text-start leading-[39px] text-2xl sm:text-xl font-semibold py-5'>
          I’m Looking For
        </div>

        {loading ? (
          <div className='w-full h-screen flex justify-center items-center '>
            <img src={LoadingImage} alt='Loading' className='w-16 h-16' />
          </div>
        ) : (
          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 gap-2 xl:gap-3 pt-4 pb-4'>
            {ServiesDvm.map((category) => (
              <div
                key={category._id}
                className='flex flex-col justify-center items-center  space-y-4 rounded-[10px] hover:shadow-lg cursor-pointer bg-[#D2D8EF66] py-2'
                onClick={() => handleCategory(city, category._id)}
              >
                <div className='w-28 h-28  bg-[#F6F7FC] rounded-full flex items-center justify-center my-2 shadow-md '>
                  <img
                    src={`https://callvet.raabtastore.com/${category.image}`}
                    alt='Category Image'
                    className='h-16 w-16'
                  />
                </div>
                <div className='text-sm text-black'>{category.name}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Lookingfor;
