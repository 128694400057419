import React from 'react'
const Abouthealthcare = ({cityname}) => {
  return (
    <div className='w-full bg-[#F8F9FA] my-4 rounded-md px-4 py-6 flex flex-col space-y-3'>
    <h2 className='py-2 text-[26px]'>About Healthcare in {cityname}</h2>
    <p className='text-[15px]'>{cityname} is the largest city of Pakistan, located in the province of Punjab. It is an ancient city with a history dating back almost 5000 years. {cityname} is known for its various shrines and mausoleums which is why it's sometimes referred to as the “City of Saints”.</p>
    <p className='text-[15px]'>Apart from its historical and cultural significance, {cityname} also caters to the medical needs of the Southern Punjab region. It has excellent medical facilities including both public and private hospitals. The hospital remain jam-packed with patients in need for treatment from the best doctors in Multan</p>

    </div>
  )
}
export default Abouthealthcare
