import React, { useState,useEffect } from 'react'
import Image1 from '../../../assests/Images/Svg/Vector (1).svg'
import Image2 from '../../../assests/Images/Svg/Evening.svg'
import Image3 from '../../../assests/Images/Svg/moon.svg'
import Login from './otp/login'
import Loadingimage from '../../../assests/Images/globalpics/loadingimg.svg'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './booking.css'
const Whichtime = ({daybooking,slots,monthbooking,setSelectime,triggerLogin,goToNextDay, dayname,daydate,daymonth}) => {
  const [selectedTime, setSelectedTime] = useState({ category: null, index: null });
  const [showLogin, setShowLogin]=useState(false);
  const [showcolored,setShowcolored]=useState(true);
  const {username,image,hospitalfee,hospitalname,homeclinic,doctorId,hospitalAvailableId,date,address,bio }=useSelector((state)=>state.appointment);
 const navigate=useNavigate();
useEffect(()=>{
   if(categorizedSlots.morning.length >0){
    setSelectime({category: 'morning', index: 0})
    setSelectime(categorizedSlots.morning[0]);
   }
},[])
useEffect(()=>{
  if(triggerLogin && categorizedSlots.morning.length >0){
    setSelectedTime({ category: 'morning', index:0});
    setSelectime(categorizedSlots.morning[0]);
    setShowLogin(true);
  }
},[triggerLogin])
  const handleLoginClose = () => {
    setSelectedTime({ category: null, index: null });
    setShowLogin(null);
  };
  const morningSlots = ['07:00', '07:20', '07:40', '08:00', '08:20', '08:40', '09:00', '09:20', '09:40', '10:00', '10:20', '10:40', '11:00', '11:20', '11:40'];
  const  afternoonSlots= ['12:00', '12:20', '12:40', '13:00', '13:20', '13:40', '14:00', '14:20', '14:40'];
  const   eveningSlots = ['12:00', '12:15', '12:30', '16:00', '16:20', '16:40', '17:00', '17:20', '17:40', '18:00', '18:20', '18:40', '19:00', '19:20', '19:40'];
      const categorizedSlots = {
        morning: slots.filter(slot => morningSlots.includes(slot)),
        afternoon: slots.filter(slot => afternoonSlots.includes(slot)),
        evening: slots.filter(slot => eveningSlots.includes(slot)),
    };
    if (slots.length === 0) {
      return(
        <>
        <div className='flex flex-col space-y-3 items-center py-44  '>
            <p className='text-center text-[--primary]'>No free slots available for selected date</p>
            <div>
            <button className=' border border-[--primary] text-[--primary] px-7 py-3 rounded-md' onClick={goToNextDay}>
               Next Availability  {dayname} {daydate} {daymonth}
            </button>
        </div>
        </div>
        </>
      ) 
    }  
    const getSelectedTime=()=>{
      if(!selectedTime.category || selectedTime.index === null) return ' ';
      const categorySlots = categorizedSlots[selectedTime.category];
      return categorySlots[selectedTime.index];
    }

    const handleTimeClick = (category, index) => {
      setSelectedTime({ category, index });
      setShowcolored(false);
      const authData = JSON.parse(localStorage.getItem('authData'));
      if (authData && authData.token && authData.user) {
        const { username:localStorageUsername, phone } = authData.user;
        console.log("User Details:", username, phone); 
        setShowLogin(false); 
        navigate('/appointmentdetail',  { state: {
          mon:monthbooking,
          day:daybooking,
          doctorname:username,
          doctorimage:image,
          fee:hospitalfee,
          time:categorizedSlots[category][index],
          hospitalname:hospitalname,
          homecheckup:homeclinic,
          bookingappionment:date,
          hospitalid:hospitalAvailableId,
          doctorId:doctorId,
          hospitaladress:address,
        } },);
      } else {
        console.error("User not found. Please log in.");
        setShowLogin(true); 
      }
      setSelectime(categorizedSlots[category][index]);
    };
   return(
      <> 
     {showLogin && (
        <div className='relative'>
          <Login time={getSelectedTime()} daybooking={daybooking}     monthbooking={monthbooking} onClose={handleLoginClose}  />
        </div>
      )}  
            <div className='w-full'>
        <div className='flex flex-col space-y-3'>

        {   
          categorizedSlots && categorizedSlots.morning && categorizedSlots.morning.length > 0 &&
            <div className='flex flex-col '>
            <div className='flex flex-row space-x-2 items-center py-3'>
              <span>
                <img src={Image1} alt='img' className='text-xl'/>
              </span>
              <span className='font-semibold text-[#232426]'>Morning Slots</span>
            </div>
                <div className='grid grid-cols-3 md:grid-cols-4 gap-5 cursor-pointer  '>
              {categorizedSlots.morning.map((time, index) => (
                <div className={`text-center py-2 rounded text-sm ${
                  showcolored && index === 0
                    ? 'bg-[--primary] text-white'
                    : selectedTime.category === 'morning' && selectedTime.index === index
                      ? 'activesbg'
                      : 'bg-white text-black'
                }`} style={{ boxShadow: '0px 0px 6px 1px #00000040' }} onClick={() => handleTimeClick('morning', index)} key={index}>
                  <p>{time}</p>
                </div>
              ))}
            </div>         
          </div>
          
        }




      {
        categorizedSlots && categorizedSlots.afternoon && categorizedSlots.afternoon.length > 0 &&
        <div className='flex flex-col '>
            <div className='flex flex-row space-x-2 items-center py-3'>
              <span>
                <img src={Image2} alt='img' className='text-xl'/>
              </span>
              <span className='font-semibold text-[#232426]'>Afternoon Slots</span>
            </div>

                  <div className='grid grid-cols-3 md:grid-cols-4 gap-5 cursor-pointer  '>


{categorizedSlots.afternoon.map((time, index) => (
  <div className={` text-center py-2 rounded text-sm ${
    selectedTime.category === 'afternoon' && selectedTime.index === index
      ? 'activesbg'
      : ''
  }`} style={{ boxShadow: '0px 0px 6px 1px #00000040' }} onClick={() => handleTimeClick('afternoon', index)} key={index}>
    <p>{time}</p>
  </div>
))}
</div>
            

               
           
          </div>
      }

      {
        categorizedSlots && categorizedSlots.evening && categorizedSlots.evening.length > 0  &&
        <div className='flex flex-col '>
            <div className='flex flex-row space-x-2 items-center py-3'>
              <span>
                <img src={Image3} alt='img' className='text-xl'/>
              </span>
              <span className='font-semibold text-[#232426]'>Evening Slots</span>
            </div>
            <div className='grid grid-cols-3 md:grid-cols-4 gap-5 cursor-pointer  '>
              {categorizedSlots.evening.map((time, index) => (
                <div className={` text-center text-black py-2 rounded text-sm ${
                  selectedTime.category === 'evening' && selectedTime.index === index
                    ? 'activesbg'
                    : ''
                }`} style={{ boxShadow: '0px 0px 6px 1px #00000040' }} onClick={() => handleTimeClick('evening', index)} key={index}>
                  <p>{time}</p>
                </div>
              ))}
            </div>
          </div>
      }


        </div>
      </div>
         
        
      </>
   )

 }



export default Whichtime
