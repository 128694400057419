import React from 'react'
import Navbar from '../../components/global/navbar'
import Banner from '../../components/global/banner'
import Booking from '../../components/pages/home/booking'
import Lookingfor from '../../components/pages/home/lookingfor'
import Letgetsyou from '../../components/pages/home/letgetsyou'
import Providers from '../../components/pages/home/providers'
import Paractices from '../../components/pages/home/paractices'
import Finddoctorcity from '../../components/pages/home/finddoctorcity'
import Footer from '../../components/global/footer'
import './home.css'
const Home = () => {
  return (
    <>
    <div className='secondary-background' >
    <Navbar />
    </div>
    <div className='secondary-background' >
     <Banner/>
    </div>
     <Booking/>
     <Lookingfor/>
     <div className="w-full  h-auto pt-28 relative bg-[#F7F9FC]">
     <Letgetsyou/>
     <Providers/>     
    </div>
     <Paractices/>
     <Finddoctorcity/>
   
     <Footer/>
    </>
     
  )
}

export default Home
