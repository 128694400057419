// all doctor api /doctor/getAllDoctor
import {setDoctorList,setLoading} from '../reducers/doctorlistreducer';
import axios from 'axios';
export const fetchapidoctors=()=>async(dispatch)=>{
 try{
    dispatch(setLoading(true));
    const response=await axios.get('https://callvet.raabtastore.com/api/doctor/getAllDoctor');
    dispatch(setDoctorList(response.data.data.docs));
 }catch(error){
    console.log('Error are showing',error);
 }
 finally{
    dispatch(setLoading(false));
}
}