import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Headroom from 'react-headroom';
import Navbar from '../../components/global/navbar';
import Findcity from '../../components/pages/city/findcity';
import Downloadapp from '../../components/pages/city/downloadapp';
import Footer from '../../components/global/footer';
import Abouthealthcare from '../../components/pages/city/abouthealthcare';
import Bestspeclist from '../../components/pages/city/bestspeclist';
import { fetchapicityinfo } from '../../redux/actions/cityaction';

const City = () => {
  const dispatch = useDispatch();
  const { city } = useParams();
  const { Cityinfo } = useSelector((state) => state.cityname);

  useEffect(() => {
    if (city) {
      dispatch(fetchapicityinfo(city));
    }
  }, [dispatch, city]);

  const cityName = Cityinfo[0]?.location?.city;

  return (
    <>
      <Headroom className='z-100'>
        <div className='navshadow bg-white hidden md:block'>
          <Navbar />
        </div>
      </Headroom>

      <div className='w-5/6 mx-auto'>
        <Findcity cityname={cityName} />
        <Bestspeclist cityname={cityName} />
        <Abouthealthcare cityname={cityName} />
        <Downloadapp />
      </div>

      <Footer />
    </>
  );
};

export default City;
