// /user/client-login api
import {setOtpLogin,setLoading,setError,setSuccess} from '../reducers/loginotpreducer';
import axios from 'axios';
export const  enterLogin = (phone) => async (dispatch) => {
dispatch(setLoading(true));
try {
  const response = await axios.post('https://callvet.raabtastore.com/api/user/client-login', {
    phone
  });
    if(response.data.success){
      dispatch(setOtpLogin(phone));
      dispatch(setSuccess(true));
    }
    else{
      dispatch(setError(response.data.data.message));
      dispatch(setSuccess(false));
    }
}
catch (error) {
    dispatch(setError('Internal server error'));
    dispatch(setSuccess(false));
} finally {
    dispatch(setLoading(false));
}

};