import React, { useState, useContext } from 'react';
import { FaBars, FaPhoneAlt } from 'react-icons/fa';
import Logo from '../../../assests/Images/globalpics/Layer 1.svg';
import Toolbars from './toolbars';
import { Link } from 'react-router-dom';
import { PatientContext } from '../../../context/pateintcontext';
import { IoIosArrowDown } from 'react-icons/io';
import Navtoobar from '../../../assests/Images/globalpics/tooglebar.svg';

const Responsivenavbar = () => {
  const { selectedPatient } = useContext(PatientContext);
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!show); // Toggle show state
  };
  return (
    <>
      <div className='relative'>
        {show && <Toolbars setShow={setShow} />}
      </div>
      <div className='w-full block lg:hidden'>
        <div className='flex justify-between w-11/12 mx-auto items-center cursor-pointer py-3'>
          <div className='flex space-x-3 items-center'>
            <div className='text-xl cursor-pointer' onClick={handleShow}>
              <img src={Navtoobar} alt='Toggle Bar' className='h-10 w-10' />
            </div>
            <div className='w-full sm:w-36 sm:h-9 flex justify-center pr-[50px] sm:flex-none sm:pr-0'>
              <Link to='/'>
                <img src={Logo} alt='Logo' className='w-36 h-full sm:w-full sm:h-full' />
              </Link>
            </div>
          </div>

          <div className='hidden sm:block'>
            <div className='flex space-x-4 items-center'>
              {selectedPatient ? (
                <p className='flex items-center space-x-2 text-[--primary] text-15 font-semibold cursor-pointer'>
                  <span>{selectedPatient}</span>
                  <span><IoIosArrowDown /></span>
                </p>
              ) : (
                <button className='bg-[#4C63BD] text-white w-[156px] h-[40px] text-[15px] rounded'>
                  Login / Signup
                </button>
              )}
              <button className='flex items-center justify-center space-x-3 w-[156px] h-[40px] bg-[#28B446] text-white text-[15px] rounded'>
                <span><FaPhoneAlt /></span>
                <span>04238900939</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Responsivenavbar;
